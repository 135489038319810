.c-side {
  @include sp {
    display: flex;
    justify-content: space-between;
  }

  &_cont {
    @include sp {
      width: 48%;
    }

    & + & {
      @include pc {
        margin: 36px 0 0;
      }
    }
  }

  &_heading {
    padding: 0 0 12px;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.12em;
    color: $main;

    @include sp {
      padding: 0 0 12px;
      font-size: 18px;
    }
  }

  &Box {
    li {
      display: flex;
      align-items: center;
      font-size: 14px;

      & + li {
        margin-top: 10px;
      }

      a {
        display: block;
        font-size: 14px;
        color: $black;

        @include pc {
          transition: color $anime;

          &:hover {
            color: $main;
          }
        }
      }
    }
  }
}
