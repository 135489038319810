.p-rp {
  &Media {
    width: 100%;
    margin: 0 auto 160px;
    padding: 120px 0 0;

    @include pc {
      max-width: 1366px;
    }

    @include sp {
      width: 90%;
      margin: 0 auto 80px;
      padding: 0;
    }

    &_cont {
      width: 78%;
      display: flex;
      align-items: flex-end;

      @include pc {
        max-width: 1057px;
      }

      @include sp {
        width: 100%;
        display: block;
      }
    }

    &_pic {
      width: 59%;
      margin: 0 32px 0 0;

      @include pc {
        max-width: 616px;
      }

      @include sp {
        width: 100%;
        margin: 0 0 32px;
      }
    }

    &_box {
      @include pc {
        flex: 1;
      }

      @include sp {
        width: 100%;
      }
    }

    &_label {
      width: 100%;
    }

    &_heading {
      padding: 24px 0 8px;
      font-size: 40px;
      font-weight: 500;
      line-height: 1.6;
      color: $main;

      @include sp {
        font-size: 20px;
      }
    }

    &_text {
      font-size: 18px;
      line-height: 1.7;

      @include sp {
        font-size: 16px;
      }
    }
  }

  &Cont {
    margin: 0 0 164px;
    padding: 72px 0 100px;
    background-color: $aquaPale;

    @include sp {
      margin: 0 0 80px;
      padding: 40px 24px;
    }

    &_box {
      width: 100%;
      margin: 0 auto;

      @include pc {
        max-width: 840px;
      }
    }

    &_heading {
      padding: 0 0 12px;
      font-size: 35px;
      font-weight: 500;
      line-height: 2;
      text-align: center;

      @include sp {
        font-size: 20px;
        line-height: 1.6;
      }
    }

    &_lead {
      padding: 0 0 4px;
      font-size: 18px;
      line-height: 1.7;
      text-align: center;

      @include sp {
        padding: 0 0 12px;
        font-size: 16px;
        text-align: left;
      }
    }

    &_text {
      font-size: 14px;
      line-height: 1.8;
      text-align: center;

      @include sp {
        padding: 0 0 0 1em;
        text-indent: -1em;
        text-align: left;
      }
    }

    &_pic {
      margin: 44px 0 0;

      @include sp {
        margin: 24px 0 0;
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .p-rpMedia_heading {
    font-size: 32px;
  }
}
