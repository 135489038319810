.p-care {
  margin: 0 0 12%;

  @include sp {
    margin: 0 0 6rem;
  }

  &Card {
    margin: 0 0 200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include pc {
      &:after {
        content: '';
        width: 28%;
      }
    }

    @include sp {
      margin: 0 0 64px;
    }

    &_list {
      width: 28%;

      @include pc {
        max-width: 280px;

        &:nth-child(n + 4) {
          margin: 88px 0 0;
        }
      }

      @include sp {
        width: 46%;

        &:nth-child(n + 3) {
          margin: 32px 0 0;
        }
      }
    }

    &_pic {
      margin: 0 0 8px;

      @include sp {
        margin: 0 0 4px;
      }
    }

    &_text {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      color: $main;

      @include sp {
        font-size: 14px;
      }
    }
  }
}
