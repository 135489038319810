.p-news {
  &_date {
    margin: 0 32px 0 0;
    display: block;
    font-size: 14px;

    @include sp {
      margin: 0 16px 0 0;
      font-size: 13px;
    }
  }

  &_label {
    a,
    span {
      padding: 4px 8px;
      display: block;
      font-size: 13px;
      border: 1px solid;

      @include pc {
        transition: color $anime, background-color $anime;
      }

      @include sp {
        font-size: 12px;
      }
    }

    &--blue {
      a,
      span {
        color: $white;
        background-color: $main;
        border-color: $main;

        @include pc {
          &:hover {
            color: $main;
            background-color: $white;
          }
        }
      }
    }
  }

  &_list {
    border-bottom: 1px solid $blackPale;

    & + & {
      margin: 40px 0 0;

      @include sp {
        margin: 20px 0 0;
      }
    }
  }

  &_link {
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 1.5;
    color: $black;

    @include pc {
      transition: color $anime;

      &:hover {
        color: $main;
      }
    }
  }

  &Box {
    &_head {
      display: flex;
      align-items: center;
    }

    &_body {
      padding: 32px 0;

      @include sp {
        padding: 16px 0;
      }
    }
  }

  &Detail {
    &_heading {
      margin: 0 0 28px;
      padding: 0 0 24px;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      border-bottom: 1px solid $gray;

      @include sp {
        margin: 0 0 16px;
        padding: 0 0 12px;
        font-size: 20px;
        line-height: 1.3;
      }
    }

    &_intro {
      width: 100%;
      margin: 0 0 60px;
      display: flex;
      justify-content: space-between;
    }

    &_cont {
      p {
        margin: 0 0 48px;
        font-size: 14px;
        line-height: 2;
        letter-spacing: 0.1em;

        @include sp {
          margin: 0 0 24px;
        }
      }
    }

    &_btn {
      width: 250px;
      margin: 80px auto 0;

      @include sp {
        width: 80%;
        margin: 40px auto 0;
      }
    }
  }
}
