.p-index {
  &Skill {
    width: 100%;
    position: relative;

    & + & {
      margin: 80px 0 0;

      @include tab {
        margin: 100px 0 0;
      }
    }

    @include pc {
      padding: 0 20px 0 0;
    }

    @include sp(1024px) {
      padding: 0;
    }

    &_pic {
      width: 51%;
      min-width: 520px;

      @include sp(1024px) {
        width: 100%;
        min-width: auto;
      }
    }

    &_box {
      min-width: 585px;
      margin: 0 0 0 -20px;
      padding: 2.6% 2%;
      position: absolute;
      top: 50%;
      left: 70%;
      transform: translate(-50%, -50%);

      @include tab {
        margin: -42px auto 0;
        padding: 40px;
      }

      @include sp(1024px) {
        width: 90%;
        min-width: auto;
        margin: -21px auto 0;
        position: relative;
        top: auto;
        left: auto;
        z-index: 2;
        transform: none;
      }

      @include sp {
        padding: 24px 20px;
      }
    }

    &_heading {
      padding: 0 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @include tab {
        width: 100%;
        display: block;
        text-align: center;
      }

      @include sp {
        padding: 0 0 16px;
        display: flex;
        justify-content: center;
      }

      &Inner {
        font-size: 28px;
        font-weight: bold;
        color: $main;

        @include sp {
          font-size: 1.6rem;
        }
      }

      &Link {
        &.pc {
          @include sp(1024px) {
            display: none !important;
          }
        }

        &.sp {
          @include tab {
            margin: 48px auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          @include sp(1024px) {
            display: block;
          }

          @include sp {
            margin: 20px 0 0;
          }
        }
      }
    }

    &_text {
      font-size: 14px;
      line-height: 1.8;

      @include sp(1024px) {
        font-size: 1.2rem;
        text-align: center;
      }
    }

    &_cont {
      padding: 20px 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include pc {
        &:after {
          content: '';
          display: block;
          width: 31%;
        }
      }

      @include tab {
        padding: 32px 0 0;
      }
    }

    &_list {
      width: 31%;

      @include pc {
        &:nth-child(n + 4) {
          margin: 12px 0 0;
        }
      }

      @include tab {
        width: 48%;

        &:nth-child(n + 3) {
          margin: 24px 0 0;
        }
      }

      @include sp {
        width: 47%;

        &:nth-child(n + 3) {
          margin: 20px 0 0;
        }
      }
    }

    &_link {
      width: 100%;
      padding: 12px 0;
      display: block;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.4;
      text-align: center;
      color: $white;
      background-color: $main;
      border-radius: 50px;
      border: 2px solid $main;

      @include pc {
        transition: color $anime, background-color $anime;

        &:hover {
          color: $main;
          background-color: $white;
        }
      }

      @include sp {
        padding: 8px 0;
        font-size: 1rem;
      }
    }
  }

  &Kv {
    position: relative;
    z-index: 1;

    &_heading {
      width: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);

      @include pc {
        // max-width: 700px;
      }

      @include sp {
        width: 95%;

        img {
          display: block !important;
        }
      }
    }

    &_hero {
      @include sp {
        width: 100%;
        height: 50vh;
        background: url('../img/index/index_hero.jpg') no-repeat center;
        background-size: cover;
      }
    }

    img {
      @include sp {
        display: none;
      }
    }

    &_pagination {
      bottom: 40px !important;
      left: 79% !important;
      transform: translate3d(-50%, 0, 0) !important;

      @include sp {
        bottom: 20px !important;
        left: 50% !important;
      }

      span {
        width: 16px !important;
        height: 16px !important;
        margin: 0 8px !important;
        opacity: 1 !important;
        background-color: $gray !important;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);

        @include sp {
          width: 12px !important;
          height: 12px !important;
          margin: 0 12px !important;
        }

        &.swiper-pagination-bullet-active {
          background-color: $white !important;
        }
      }
    }
  }

  &Support {
    margin: 80px auto 164px;
    display: flex;
    justify-content: space-between;

    @include pc {
      width: 100%;
      max-width: 1040px;
      padding: 0 20px;
      box-sizing: border-box;
    }

    @include tab {
      margin: 100px auto 120px;
    }

    @include sp(1024px) {
      margin: 40px auto 80px;
      display: block;
    }

    &_cont {
      width: 47%;

      @include pc {
        max-width: 470px;
      }

      @include tab {
        & + & {
          margin: 100px 0 0;
        }
      }

      @include sp(1024px) {
        width: 100%;
        max-width: none;
      }

      @include sp {
        & + & {
          margin: 80px 0 0;
        }
      }
    }

    &_pic {
      position: relative;
      z-index: 1;
    }

    &_box {
      width: 80%;
      height: 216px;
      margin: -60px auto 0;
      padding: 32px;
      position: relative;
      z-index: 2;

      @include sp(1024px) {
        width: 90%;
        height: auto;
        min-width: auto;
        margin: -21px auto 0;
        padding: 24px 20px;
      }
    }

    &_heading {
      padding: 0 0 16px;
      font-size: 28px;
      font-weight: bold;
      color: $main;

      @include sp(1024px) {
        text-align: center;
      }

      @include sp {
        font-size: 20px;
      }
    }

    &_text {
      font-size: 14px;
      line-height: 1.8;

      @include sp {
        text-align: left;
      }
    }

    &_link {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 32px;
      right: 32px;

      @include sp(1024px) {
        margin: 24px 0 0;
        justify-content: center;
        position: static;
      }
    }
  }

  &Meister {
    margin: 0 0 160px;
    padding: 68px 0 80px;
    background-color: $aquaPale;

    @include sp {
      margin: 0 0 80px;
      padding: 3rem 0 4rem;
    }

    &_label {
      margin: 0 0 36px;

      @include sp {
        margin: 0 0 1.2rem;
      }
    }

    &_heading {
      padding: 0 0 36px;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: center;
      color: $blackPale;

      @include sp {
        padding: 0 0 1.2rem;
        font-size: 1.6rem;
      }
    }

    &_cont {
      display: flex;
      justify-content: space-between;

      @include sp {
        flex-flow: column;
      }
    }

    &_box {
      width: 100%;
      // display: flex;
      // flex-flow: column;
      // align-items: center;
    }

    &_link {
      margin: 36px 0 0;
      display: flex;
      justify-content: center;

      @include sp {
        justify-content: center;
      }
    }

    &_pic {
      width: 44%;
      margin: 36px auto 0;

      @include pc {
        max-width: 433px;
      }

      @include sp {
        width: 100%;
        margin: 1.8rem auto 0;
        order: 0;
      }
    }
  }

  &News {
    width: 100%;
    margin: 0 auto 180px;
    padding: 56px 52px;
    background-color: $grayPale;
    position: relative;
    z-index: 1;

    @include pc {
      max-width: 840px;
    }

    @include sp {
      margin: 0 0 80px;
      padding: 36px 12px;
    }

    &_intro {
      width: 100%;
      padding: 0 0 0 56px;
      display: flex;
      justify-content: space-between;

      @include pc {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
      }

      @include sp {
        padding: 0 0 28px;
        align-items: center;
      }
    }

    &_heading {
      margin: -22px 0 0;
      display: inline-block;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 0.12em;
      color: $blackPale;

      @include sp {
        margin: 0;
        font-size: 1.6rem;
      }
    }

    &_link {
      padding: 8px 20px;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.1em;
      color: $white;
      background-color: $gray;
      border: 2px solid $gray;

      @include pc {
        transition: color $anime, background-color $anime;

        &:hover {
          color: $gray;
          background-color: $white;
        }
      }

      @include sp {
        padding: 8px 12px;
        font-size: 1.2rem;
      }
    }

    &_list {
      & + & {
        margin: 20px 0 0;
      }
    }

    &_item {
      display: block;
      font-size: 14px;
      line-height: 1.8;
      color: $blackPale;

      @include pc {
        transition: opacity $anime;

        &:hover {
          opacity: 0.7;
        }
      }

      @include sp {
        font-size: 12px;
        line-height: 1.6;
      }
    }

    &_cont {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include sp {
        display: block;
      }
    }

    &_head {
      margin: 0 12px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include sp {
        padding: 0 0 12px;
        justify-content: flex-start;
      }
    }

    &_body {
      align-items: flex-start;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      @include pc {
        flex: 1;
      }

      @include sp {
        a {
          font-size: 1.2rem;
        }
      }
    }

    &_text {
      font-size: 14px;
      line-height: 1.8;

      @include sp {
        font-size: 1.2rem;
        line-height: 1.6;
      }
    }

    &_label {
      margin: 2px 0 0;

      @include sp {
        a {
          font-size: 1.2rem;
        }
      }

      .post-categories {
        margin: 0 0 8px;
      }
    }
  }
}

.post-categories {
  display: flex;

  @include sp {
    margin: 0 0 8px;
  }

  li {
    & + li {
      margin: 0 0 0 12px;
    }
  }
}
