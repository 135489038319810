.c-text {
  font-size: 14px;
  line-height: 1.8;

  @include sp {
    font-size: 1.2rem;
  }

  &--center {
    @extend .c-text;
    text-align: center;

    &--pc {
      @extend .c-text;
      @include pc {
        text-align: center;
      }
    }

    &--sp {
      @extend .c-text;
      @include sp {
        text-align: center;
      }
    }
  }

  &--indent {
    @extend .c-text;
    padding: 0 0 0 1em;
    text-indent: -1em;
  }
}
