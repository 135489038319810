.p-conf {
  margin: 0 0 48px;

  @include sp {
    margin: 0 0 32px;
  }

  &_text {
    font-size: 16px;
    line-height: 2;
    text-align: center;
    letter-spacing: 0.025em;

    @include sp {
      font-size: 1.2rem;
      text-align: left;
    }
  }

  &_list {
    margin: 0 0 160px;

    @include sp {
      margin: 0 0 80px;
    }
  }
}
