.p-medical {
  width: 100%;
  margin: 0 0 15%;

  @include sp {
    margin: 0 0 8rem;
  }

  &_inner {
    & + & {
      margin: 12% 0 0;

      @include sp {
        margin: 6rem 0 0;
      }
    }
  }

  &_cont {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include sp {
      flex-flow: column;
    }
  }

  &_box {
    @include pc {
      flex: 1;
    }

    @include sp {
      width: 100%;
      order: 1;
    }
  }

  &_pic {
    width: 50%;

    @include pc {
      max-width: 494px;
    }

    @include sp {
      width: 100%;
      margin: 0 0 2rem;
      order: 0;
    }

    &--right {
      @extend .p-medical_pic;

      @include pc {
        margin: 0 0 0 3%;
      }
    }

    &--left {
      @extend .p-medical_pic;

      @include pc {
        margin: 0 3% 0 0;
      }
    }
  }

  &_img {
    width: 633px;
    margin: 5% auto 0;

    @include sp {
      width: 80%;
      margin: 3rem auto 0;
    }
  }

  &Info {
    width: 100%;
    margin: 24px 0 0;
    padding: 16px;
    background-color: $aquaPale;

    @include sp {
      margin: 2rem 0 0;
      padding: 1.5rem;
    }

    &_lead {
      padding: 0 0 8px;
      font-size: 18px;
      font-weight: bold;

      @include sp {
        font-size: 1.3rem;
      }
    }

    &_list {
      display: flex;
    }

    &_ico {
      width: 14px;
      height: 14px;
      margin: 6px 8px 0 0;
      display: block;
      background-color: $main;
    }
  }
}
