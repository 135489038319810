.c-box {
  box-shadow: 10px 10px 10px rgba($black, 0.15);
  background-color: $white;
  position: relative;

  @include sp(1024px) {
    box-shadow: 0 10px 10px rgba($black, 0.3);
  }

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 21px 21px 0 0;
    border-color: $main transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    @include tab {
      border-width: 42px 42px 0 0;
    }

    @include sp {
      border-width: 21px 21px 0 0;
    }
  }
}
