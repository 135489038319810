.p-reha {
  &Power {
    margin: 0 0 10%;

    @include sp {
      margin: 0 0 5rem;
    }

    &_main {
      width: 566px;
      margin: 0 auto 6%;

      @include sp {
        width: 90%;
        margin: 0 auto 3rem;
      }
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @include sp {
        display: block;
      }
    }

    &_list {
      width: 28%;

      @include pc {
        max-width: 280px;
      }

      @include sp {
        width: 100%;

        & + & {
          margin: 3rem 0 0;
        }
      }
    }

    &_label {
      margin: 0 0 16px;
      padding: 8px 0;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.02em;
      text-align: center;
      color: $white;

      @include sp {
        margin: 0 0 1.5rem;
        padding: 1rem 0;
        font-size: 1.4rem;
      }

      &--yellow {
        @extend .p-rehaPower_label;
        background-color: $yellow;
      }

      &--blue {
        @extend .p-rehaPower_label;
        background-color: $blue;
      }

      &--pink {
        @extend .p-rehaPower_label;
        background-color: $pink;
      }
    }

    &_pic {
      margin: 0 0 16px;

      @include sp {
        margin: 0 0 1.5rem;
      }
    }
  }

  &Card {
    margin: 5% 0 10%;
    display: flex;
    justify-content: space-between;

    @include sp {
      margin: 0 0 6rem;
      display: block;
    }

    &_list {
      width: 28%;

      @include pc {
        max-width: 280px;
      }

      @include sp {
        width: 100%;

        & + & {
          margin: 3rem 0 0;
        }
      }
    }

    &_heading {
      margin: 0 0 28px;
      padding: 0 0 16px;
      font-size: 20px;
      font-weight: 500;
      color: $main;
      border-bottom: 2px solid $main;

      @include sp {
        margin: 0 0 16px;
        padding: 0 0 8px;
        font-size: 18px;
      }
    }

    &_pic {
      margin: 0 0 12px;
    }

    &_text {
      font-size: 14px;
      line-height: 1.7;
    }
  }
}
