.p-tech {
  margin: 0 0 160px;

  &_circle {
    width: 62%;
    margin: 0 auto 128px;

    @include pc {
      max-width: 840px;
    }

    @include sp {
      width: 90%;
      margin: 0 auto 64px;
    }
  }

  &_wrap {
    width: 100%;
    margin: 0 auto;

    @include pc {
      max-width: 650px;
    }

    @include sp {
      width: 90%;
    }
  }

  &_cont {
    padding: 40px 0 0;
    display: flex;
    justify-content: space-between;

    @include sp {
      padding: 24px 0 0;
      display: block;

      & + & {
        padding: 80px 0 0;
      }
    }
  }

  &_pic {
    width: 46%;

    @include pc {
      max-width: 293px;
    }

    @include sp {
      width: 100%;
    }
  }

  &_box {
    @include pc {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &_heading {
    width: 100%;
    padding: 16px 0 16px 15%;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.6;
    text-align: left;
    border-bottom: 2px solid;

    @include sp {
      padding: 16px 0;
      font-size: 20px;
      text-align: center;
    }

    &--pink {
      color: $pink;
      border-color: $pink;
    }

    &--orange {
      color: $orange;
      border-color: $orange;
    }

    &--yellow {
      color: $yellow;
      border-color: $yellow;
    }

    &--green {
      color: $green;
      border-color: $green;
    }

    &--blue {
      color: $blue;
      border-color: $blue;
    }
  }

  &_btn {
    width: 80%;

    @include pc {
      max-width: 294px;
    }

    @include sp {
      margin: 32px auto 0;
    }

    &Inner {
      padding: 16px 0 16px 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 2;
      letter-spacing: 0.025em;
      color: $white;
      border: 2px solid;
      border-radius: 100px;

      @include pc {
        transition: color $anime, background-color $anime;

        .p-tech_btnArrow {
          transition: background-color $anime;

          &:after {
            transition: border-color $anime;
          }
        }

        &:hover {
          background-color: $white;
        }
      }

      @include sp {
        padding: 12px 0 12px 24px;
        font-size: 14px;
      }

      &--pink {
        background-color: $pink;
        border-color: $pink;

        @include pc {
          &:hover {
            color: $pink;

            .p-tech_btnArrow {
              background-color: $pink;

              &:after {
                border-color: $pink;
              }
            }
          }
        }
      }

      &--orange {
        background-color: $orange;
        border-color: $orange;

        @include pc {
          &:hover {
            color: $orange;

            .p-tech_btnArrow {
              background-color: $orange;

              &:after {
                border-color: $orange;
              }
            }
          }
        }
      }

      &--yellow {
        background-color: $yellow;
        border-color: $yellow;

        @include pc {
          &:hover {
            color: $yellow;

            .p-tech_btnArrow {
              background-color: $yellow;

              &:after {
                border-color: $yellow;
              }
            }
          }
        }
      }

      &--green {
        background-color: $green;
        border-color: $green;

        @include pc {
          &:hover {
            color: $green;

            .p-tech_btnArrow {
              background-color: $green;

              &:after {
                border-color: $green;
              }
            }
          }
        }
      }

      &--blue {
        background-color: $blue;
        border-color: $blue;

        @include pc {
          &:hover {
            color: $blue;

            .p-tech_btnArrow {
              background-color: $blue;

              &:after {
                border-color: $blue;
              }
            }
          }
        }
      }
    }

    &Arrow {
      width: 100%;
      height: 1px;
      margin: 0 0 0 12px;
      background-color: $white;
      position: relative;

      &:after {
        content: '';
        width: 8px;
        height: 8px;
        border-right: 1px solid $white;
        border-bottom: 1px solid $white;
        position: absolute;
        top: calc(50% + 0.5px);
        right: 0;
        transform: translateY(-50%) rotate(-45deg);
      }

      @include pc {
        max-width: 70px;
      }

      @include sp {
        width: 40px;
      }
    }
  }

  &Intro {
    width: 100%;
    margin: -28px auto 140px;
    display: flex;
    position: relative;
    z-index: 1;

    @include pc {
      max-width: 840px;
    }

    @include sp {
      width: 90%;
      margin: 32px auto 64px;
      display: block;
    }

    &_box {
      padding: 24px 56px;
      display: flex;
      flex-flow: column;
      justify-content: center;

      @include pc {
        flex: 1;
      }

      @include sp {
        width: 100%;
        padding: 32px;
      }

      &--pink {
        background-color: $pink;
      }

      &--orange {
        background-color: $orange;
      }

      &--yellow {
        background-color: $yellow;
      }

      &--green {
        background-color: $green;
      }

      &--blue {
        background-color: $blue;
      }
    }

    &_heading {
      padding: 0 0 24px;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.4;
      color: $white;

      @include sp {
        padding: 0 0 8px;
        font-size: 20px;
      }
    }

    &_text {
      font-size: 14px;
      line-height: 2;
      color: $white;
    }

    &_pic {
      width: 48%;

      @include pc {
        max-width: 406px;
      }

      @include sp {
        width: 100%;
      }
    }
  }

  &Media {
    display: flex;
    justify-content: space-between;

    @include sp {
      display: block;
    }

    & + & {
      margin: 68px 0 0;

      @include sp {
        margin: 40px 0 0;
      }
    }

    &_pic {
      width: 49%;

      @include pc {
        max-width: 490px;
      }

      @include sp {
        width: 100%;
      }

      &--left {
        margin: 0 40px 0 0;

        @include sp {
          margin: 0 0 32px;
        }
      }

      &--right {
        margin: 0 0 0 40px;

        @include sp {
          margin: 32px 0 0;
        }
      }

      &Head {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        @include sp {
          display: block;
        }

        img {
          width: 82%;

          @include pc {
            max-width: 400px;
          }

          @include sp {
            width: 100%;
          }
        }
      }

      &Body {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @include sp {
          display: block;
        }
      }
    }

    &_cont {
      width: 82%;
      margin: -20px 0 0;
      padding: 4px;
      background-color: $main;

      @include sp {
        width: 100%;
        margin: 0;
      }
    }

    &_box {
      @include pc {
        flex: 1;
      }

      &Inner {
        & + & {
          margin: 48px 0 0;

          @include sp {
            margin: 24px 0 0;
          }
        }
      }

      @include sp {
        width: 100%;
      }
    }

    &_lead {
      padding: 0 0 16px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      color: $main;

      @include sp {
        padding: 0 0 8px;
        font-size: 16px;
      }
    }

    &_text {
      font-size: 14px;
      line-height: 1.6;
    }
  }
}
