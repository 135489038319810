.l-wrap {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;

  @include pc {
    max-width: 1040px;
  }

  @include sp {
    width: 90%;
    padding: 0;
  }

  &Box {
    width: 72%;
    margin: -40px auto 0;
    padding: 8%;
    background-color: $white;
    position: relative;

    @include pc {
      max-width: 722px;
    }

    @include sp {
      width: 95%;
      padding: 8% 4%;
    }

    &--large {
      width: 84%;

      @include pc {
        max-width: 840px;
      }
    }
  }

  &Side {
    width: 22%;
    padding: 4%;
    background-color: $white;
    border: 1px solid $main;
    border-radius: 12px;
    position: absolute;
    top: 92px;
    right: 0;
    z-index: 2;

    @include pc {
      max-width: 220px;
    }

    @include sp {
      width: 95%;
      margin: 40px auto;
      padding: 6%;
      position: static;
    }
  }

  &Full {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;

    @include pc {
      max-width: 1406px;
    }

    @include sp {
      width: 100%;
      padding: 0;
    }
  }
}
