.c-link {
  &Inner {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    @include pc {
      transition: opacity $anime;

      &:hover {
        opacity: 0.7;
      }
    }

    @include tab {
      font-size: 16px;
    }

    @include sp(1024px) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

    @include sp {
      font-size: 1.2rem;
    }

    &--blue {
      color: $main;

      .c-linkInner_arrow {
        @include pc {
          background-color: $main;

          &:after {
            border-color: transparent transparent transparent $main;
          }
        }

        @include sp(1024px) {
          // background: none;

          // &:after {
          //   border-color: $white;
          // }
        }
      }
    }

    &--black {
      color: $blackPale;

      @include sp {
        color: $main;
      }

      .c-linkInner_arrow {
        @include pc {
          background-color: $blackPale;

          &:after {
            border-color: transparent transparent transparent $blackPale;
          }
        }

        @include sp(1024px) {
          // background: none;

          // &:after {
          //   border-color: $white;
          // }
        }
      }
    }

    &_arrow {
      width: 68px;
      height: 1px;
      margin: 0 0 0 16px;
      position: relative;

      @include tab {
        width: 16px;
        height: 16px;
        margin: 4px 0 0;
        box-sizing: border-box;
        border-right: 3px solid $main;
        border-bottom: 3px solid $main;
        transform: rotate(45deg);
      }

      @include sp {
        width: 1rem;
        height: 1rem;
        margin: 0.5rem 0 0;
        box-sizing: border-box;
        border-right: 2px solid $main;
        border-bottom: 2px solid $main;
        transform: rotate(45deg);
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 0 6px 6px;
        border-color: transparent transparent transparent $main;
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translateY(-50%);

        @include tab {
          display: none;
        }

        @include sp {
          display: none;
        }
      }
    }
  }
}
