.p-nurse {
  &Box {
    width: 100%;
    margin: 0 auto 160px;
    padding: 48px;
    border: 2px solid $main;

    @include pc {
      max-width: 834px;
    }

    @include sp {
      width: 90%;
      margin: 0 auto 80px;
      padding: 24px;
    }

    &_cont {
      display: flex;
      justify-content: space-between;

      & + & {
        margin: 24px 0 0;
      }
    }

    &_head {
      width: 64px;
      margin: 0 24px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      @include sp {
        width: 60%;
        margin: 0 16px 0 0;
        align-items: flex-start;
      }
    }

    &_body {
      @include pc {
        flex: 1;
      }
    }

    &_lead {
      padding: 0 0 12px;
      font-size: 20px;
      font-weight: 500;
      color: $main;

      @include sp {
        font-size: 16px;
      }
    }

    &_text {
      font-size: 14px;
      line-height: 1.7;
    }
  }

  &Flow {
    margin: 0 0 356px;
    padding: 100px 0 80px;
    background-color: $aquaPale;
    position: relative;
    z-index: 1;

    @include tab {
      margin: 0 0 120px;
    }

    @include sp {
      margin: 0 0 64px;
      padding: 56px 24px 40px;
    }

    &_circle {
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $main;
      position: absolute;
      top: -60px;
      left: 50%;
      z-index: 2;
      transform: translateX(-50%);
      border-radius: 50%;

      @include sp {
        width: 60px;
        height: 60px;
        top: -30px;
      }

      img {
        width: 74px;

        @include sp {
          width: 37px;
        }
      }
    }

    &_heading {
      padding: 0 0 16px;
      font-size: 35px;
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      color: $main;

      @media screen {
        font-size: 20px;
      }

      &Inner {
        display: block;
        font-size: 25px;

        @include sp {
          font-size: 16px;
        }
      }
    }

    &_text {
      font-size: 16px;
      line-height: 2;
      text-align: center;

      @include sp {
        font-size: 14px;
        text-align: left;
      }
    }

    &_box {
      width: 100%;
      margin: 44px auto 0;

      @include pc {
        max-width: 430px;
      }

      @include sp {
        width: 90%;
        margin: 24px auto 0;
      }
    }

    &_cont {
      display: flex;
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        border-bottom: 2px dotted $white;
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        z-index: 2;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }

    &_head {
      width: 142px;
      padding: 12px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $pinkPale;
      position: relative;
      z-index: 1;

      @include sp {
        width: 40%;
      }

      &--deep {
        background-color: $pink;
      }

      img {
        width: auto;
        height: 32px;
        display: block;

        @include sp {
          height: 24px;
        }
      }
    }

    &_arrow {
      width: 18px;
      height: calc(100% - 7px);
      background: linear-gradient(180deg, rgba(255, 212, 151, 1) 0%, rgba(255, 170, 48, 1) 99.71%);
      position: absolute;
      top: 0;
      right: calc(100% + 10px);
      z-index: 2;

      &:after {
        content: '';
        margin: -1px 0 0;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 9px 0 9px;
        border-color: rgba(255, 170, 48, 1) transparent transparent transparent;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }

    &_body {
      padding: 16px;
      display: flex;
      align-items: center;
      flex: 1;
      font-size: 14px;
      line-height: 1.6;
      background-color: $grayPale02;

      &--deep {
        background-color: $grayPale;
      }
    }
  }

  &Other {
    width: 100%;
    margin: 0 auto 156px;
    display: flex;
    justify-content: space-between;

    @include pc {
      max-width: 1000px;
    }

    @include sp(1024px) {
      width: 90%;
      margin: 0 auto 80px;
      display: block;
    }

    &_box {
      width: 46%;
      padding: 86px 40px 40px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      background-color: $aquaPale;
      position: relative;
      z-index: 1;

      @include pc {
        max-width: 460px;
      }

      @include sp(1024px) {
        width: 100%;
        padding: 32px 16px;
        max-width: none;

        & + & {
          margin: 80px 0 0;
        }
      }
    }

    &_pic {
      width: calc(100% - 80px);
      position: absolute;
      bottom: 90%;
      left: 50%;
      transform: translateX(-50%);

      @include sp(1024px) {
        width: 100%;
        margin: 0 0 32px;
        position: static;
        transform: none;
      }
    }

    &_intro {
      margin: 0 0 28px;
    }

    &_lead {
      padding: 0 0 16px;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: $main;
    }

    &_text {
      font-size: 14px;
      line-height: 2;
      text-align: center;

      @include sp {
        text-align: left;
      }
    }

    &_wrap {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: flex-end;
    }

    &_cont {
      padding: 8px;
      display: flex;
      align-items: center;

      &--green01 {
        width: 85%;
        background-color: $green01;

        @include sp {
          width: 100%;
        }
      }

      &--green02 {
        width: 90%;
        background-color: $green02;

        @include sp {
          width: 100%;
        }
      }

      &--green03 {
        width: 95%;
        background-color: $green;

        @include sp {
          width: 100%;
        }
      }

      &--green04 {
        width: 100%;
        background-color: $green03;
      }

      &Head {
        width: 52px;
        height: 52px;
        margin: 0 8px 0 0;
        padding: 0 0 4px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        background-color: $white;
        border-radius: 50%;

        @include sp {
          width: 48px;
          height: 48px;
        }

        &--green01 {
          color: $green01;
        }

        &--green02 {
          color: $green02;
        }

        &--green03 {
          color: $green;
        }

        &--green04 {
          color: $green03;
        }
      }

      &Num {
        font-size: 26px;

        @include sp {
          font-size: 20px;
        }
      }

      &Body {
        flex: 1;
      }

      &Lead {
        padding: 0 0 8px;
        font-size: 16px;
        font-weight: 500;
        color: $white;
      }

      &Text {
        font-size: 13px;
        color: $white;
      }
    }

    &_info {
      width: 90%;
      margin: 0 auto;

      @include sp {
        width: 100%;
      }

      & + & {
        margin: 26px auto 0;
      }

      &Head {
        padding: 16px;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        color: $white;
        background-color: $main;

        @include sp {
          font-size: 18px;
        }
      }

      &Body {
        padding: 20px 16px;
        font-size: 14px;
        line-height: 1.5;
        background-color: $white;

        &--center {
          text-align: center;

          @include sp {
            text-align: left;
          }
        }
      }
    }
  }
}
