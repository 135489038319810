.c-intro {
  width: 100%;
  margin: 0 auto 340px;
  position: relative;
  z-index: 2;

  @include pc {
    max-width: 840px;
  }

  @include sp {
    margin: 40px auto 80px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  &_box {
    width: 54%;
    margin: -32px 0 0;
    padding: 88px 60px 72px;
    background-color: $main;
    position: relative;
    z-index: 1;

    @include pc {
      max-width: 452px;
    }

    @include sp {
      width: 100%;
      padding: 72px 40px 52px;
      order: 1;
    }
  }

  &_lead,
  &_text {
    color: $white;
  }

  &_lead {
    padding: 0 0 24px;
    font-size: 28px;
    font-weight: normal;
    line-height: 1.4;

    @include sp {
      margin: 0 0 16px;
      padding: 0 0 12px;
      font-size: 1.6rem;
      text-align: center;
      position: relative;

      &:after {
        content: '';
        width: 60px;
        height: 2px;
        background-color: $white;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &_text {
    font-size: 14px;
    line-height: 2;

    @include sp {
      font-size: 1.2rem;
    }
  }

  &_pic {
    width: 52%;
    position: absolute;
    top: 184px;
    right: 0;
    z-index: 0;

    @include pc {
      max-width: 434px;
    }

    @include sp {
      width: 80%;
      order: 0;
      position: relative;
      top: auto;
      right: auto;
      z-index: 2;
    }
  }

  &Scroll {
    position: absolute;
    top: -48px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);

    @include sp {
      display: none;
    }

    &_text {
      &Inner {
        display: block;
        font-size: 14px;
        font-weight: bold;
        color: $white;
        transform: rotate(90deg);
      }
    }

    &_bar {
      width: 2px;
      height: 55px;
      margin: 6px auto 0;
      display: block;
      background: linear-gradient(to bottom, rgba($white, 1) 50%, rgba($white, 0) 50%);
      background-position: 0 -60px;
      background-size: 100% 200%;
      animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;

      @include sp {
        height: 63px;
      }
    }

    @keyframes scrolldown {
      0% {
        background-position: 0 -55px;
      }
      75% {
        background-position: 0 0;
      }
      100% {
        background-position: 0 55px;
      }
    }
  }
}
