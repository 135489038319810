.c-kv {
  width: 100%;
  position: relative;

  &_hero {
    &--human {
      @include sp {
        width: 100%;
        height: 35vh;
        background: url('../img/human/human_hero.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &--conference {
      @include sp {
        width: 100%;
        height: 35vh;
        background: url('../img/conference/conference_hero.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &--technical {
      @include sp {
        width: 100%;
        height: 35vh;
        background: url('../img/technical/technical_hero.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &--qualification {
      @include sp {
        width: 100%;
        height: 35vh;
        background: url('../img/qualification/qualification_hero.jpg') no-repeat center;
        background-size: cover;
      }

      &--interview {
        @include sp {
          width: 100%;
          height: 35vh;
          background: url('../img/qualification/qualification_hero.jpg') no-repeat center;
          background-size: cover;
        }
      }
    }

    &--learning {
      @include sp {
        width: 100%;
        height: 35vh;
        background: url('../img/learning/learning_hero.jpg') no-repeat center;
        background-size: cover;
      }

      &--interview {
        @include sp {
          width: 100%;
          height: 35vh;
          background: url('../img/learning/interview/interview_learning_hero.jpg') no-repeat center;
          background-size: cover;
        }
      }
    }

    &--reception {
      @include sp {
        width: 100%;
        height: 35vh;
        background: url('../img/reception/reception_hero.jpg') no-repeat center;
        background-size: cover;
      }
    }
  }

  &_heading {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: $main;
    position: absolute;
    top: 50%;
    right: 15%;
    transform: translateY(-50%);
    white-space: nowrap;

    @include pc {
      border-bottom: 3px solid $main;
    }

    @include sp {
      padding: 8px 32px;
      font-size: 22px;
      font-weight: 500;
      color: $white;
      background-color: $main;
      right: auto;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  img {
    @include sp {
      display: none;
    }
  }
}
