.l-footer {
  margin: 120px 0 0;
  padding: 60px 0 40px;
  background-color: $aquaPale;
  position: relative;

  &.is-animated {
    .l-footer_btn {
      position: absolute;
      bottom: 83%;
    }
  }

  @include sp {
    margin: 8rem 0 0;
    padding: 3rem 0 2rem;
  }

  &_logo {
    width: 146px;
    margin: 0 auto 88px;

    @include sp {
      width: 35%;
      margin: 0 auto 4rem;
    }
  }

  &_btn {
    width: 80px;
    height: 80px;
    background-color: $main;
    border: 2px solid $main;
    border-radius: 50%;
    position: fixed;
    bottom: 20px;
    right: 12%;
    z-index: 100;
    opacity: 0;
    transition: opacity $anime;

    @include pc {
      transition: background-color $anime, opacity $anime;

      &:after {
        transition: border-color $anime;
      }

      &:hover {
        background-color: $white;

        &:after {
          border-color: $main;
        }
      }
    }

    @include sp {
      width: 4rem;
      height: 4rem;
      bottom: 2rem;
      right: 2rem;
    }

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      border-top: 2px solid $white;
      border-right: 2px solid $white;
      position: absolute;
      top: calc(50% + 5px);
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);

      @include sp {
        width: 8px;
        height: 8px;
        top: calc(50% + 2px);
      }
    }

    &.is-view {
      opacity: 1;
    }
  }

  &_copy {
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.025em;

    @include sp {
      font-size: 10px;
    }
  }
}
