.c-label {
  padding: 6px 8px;
  display: inline-block;
  font-size: 14px;

  @include sp {
    padding: 4px 6px;
    font-size: 12px;
  }

  &--gray {
    color: $white;
    background-color: $gray;
  }

  &--heading {
    padding: 8px 36px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.12em;
    color: $white;

    @include sp {
      font-size: 1.1rem;
    }

    &--aqua {
      background-color: $main;
    }
  }

  &Wrap {
    padding: 4px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
