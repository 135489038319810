.p-human {
  padding: 10% 0;

  @include sp {
    padding: 4rem 0;
  }

  &Intro {
    width: 100%;
  }

  &Message {
    &_pic {
      width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 2;

      @include pc {
        max-width: 840px;
      }

      @include sp {
        width: 90%;
      }
    }

    &_cont {
      width: 100%;
      margin: -5% 0 0;
      padding: 8% 0;
      background-color: $aquaPale;

      @include sp {
        margin: -2.5rem 0 0;
        padding: 5rem 0;
      }
    }

    &_inner {
      width: 540px;
      margin: 0 auto;

      @include sp {
        width: 90%;
      }
    }

    &_box {
      & + & {
        margin: 28px 0 0;

        @include sp {
          margin: 2rem 0 0;
        }
      }
    }
  }

  &Job {
    padding: 10% 0;

    @include sp {
      padding: 6rem 0;
    }

    &_pic {
      width: 617px;
      margin: 5% auto 0;

      @include sp {
        width: 95%;
        margin: 2rem auto 0;
      }
    }
  }

  &Detail {
    width: 100%;
    margin: 4% 0 12%;

    @include sp {
      margin: 3rem 0 8rem;
    }

    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include sp {
        display: block;
      }

      & + & {
        margin: 6% 0 0;

        @include sp {
          margin: 4rem 0 0;
        }
      }
    }

    &_label {
      width: 36%;
      margin: 0 2% 0 0;
      padding: 12px 0;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
      color: $white;

      @include pc {
        max-width: 356px;
      }

      @include tab {
        font-size: 24px;
      }

      @include sp {
        width: 100%;
        margin: 0 0 1rem;
        padding: 1rem 0;
        font-size: 1.8rem;
      }

      &--green {
        @extend .p-humanDetail_label;
        background-color: $green;
      }

      &--pink {
        @extend .p-humanDetail_label;
        background-color: $pink;
      }

      &--blue {
        @extend .p-humanDetail_label;
        background-color: $blue;
      }
    }

    &_inner {
      @include pc {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @include sp {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
      }
    }

    &_text {
      @include pc {
        flex: 1;
      }
    }

    &_btn {
      width: 64px;
      margin: 0 0 0 2%;
      display: block;

      @include pc {
        .p-humanDetail_btn {
          &Circle {
            transition: background-color $anime;

            &:before,
            &:after {
              transition: background-color $anime;
            }
          }
        }

        &:hover {
          .p-humanDetail_btn {
            &Circle {
              &--green {
                background-color: $green;

                &:before,
                &:after {
                  background-color: $white;
                }
              }

              &--pink {
                background-color: $pink;

                &:before,
                &:after {
                  background-color: $white;
                }
              }

              &--blue {
                background-color: $blue;

                &:before,
                &:after {
                  background-color: $white;
                }
              }
            }
          }
        }
      }

      @include tab {
        width: 56px;
      }

      @include sp {
        width: 6rem;
        margin: 2rem 0 0;
      }

      &Circle {
        width: 64px;
        height: 64px;
        display: block;
        background-color: $white;
        border: 1px solid;
        border-radius: 50%;
        position: relative;
        z-index: 1;

        @include tab {
          width: 56px;
          height: 56px;
        }

        @include sp {
          width: 4rem;
          height: 4rem;
          margin: 0 auto;
        }

        &:before,
        &:after {
          content: '';
          width: 32px;
          height: 1px;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;

          @include tab {
            width: 24px;
          }

          @include sp {
            width: 2rem;
          }
        }

        &:before {
          transform: translate(-50%, -50%);
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-90deg);
        }

        &--green {
          @extend .p-humanDetail_btnCircle;
          border-color: $green;

          &:before,
          &:after {
            background-color: $green;
          }
        }

        &--pink {
          @extend .p-humanDetail_btnCircle;
          border-color: $pink;

          &:before,
          &:after {
            background-color: $pink;
          }
        }

        &--blue {
          @extend .p-humanDetail_btnCircle;
          border-color: $blue;

          &:before,
          &:after {
            background-color: $blue;
          }
        }
      }

      &Text {
        padding: 8px 0 0;
        display: block;
        font-size: 12px;
        font-weight: bold;
        text-align: center;

        @include tab {
          font-size: 10px;
        }

        @include sp {
          font-size: 1rem;
        }

        &--green {
          @extend .p-humanDetail_btnText;
          color: $green;
        }

        &--pink {
          @extend .p-humanDetail_btnText;
          color: $pink;
        }

        &--blue {
          @extend .p-humanDetail_btnText;
          color: $blue;
        }
      }
    }
  }

  &About {
    width: 84%;
    margin: 12% auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include pc {
      max-width: 837px;
    }

    @include sp {
      width: 100%;
      margin: 5rem auto;
      display: block;
    }

    &_box {
      @include pc {
        flex: 1;
      }

      @include sp {
        width: 100%;
      }

      &Inner {
        & + & {
          margin: 4% 0 0;

          @include sp {
            margin: 2rem 0 0;
          }
        }
      }
    }

    &_pic {
      width: 293px;
      margin: 0 0 0 5%;

      @include sp {
        width: 100%;
        margin: 3rem 0 0;
      }
    }
  }

  &Flow {
    width: 69%;
    margin: 10% auto;

    @include pc {
      max-width: 683px;
    }

    @include sp {
      width: 100%;
      margin: 4rem auto;
    }

    &_heading {
      margin: 0 0 4%;
      font-size: 28px;
      font-weight: bold;
      text-align: center;

      @include sp {
        margin: 0 0 2rem;
        font-size: 1.6rem;
      }
    }

    &_cont {
      padding: 3%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $aquaPale;

      @include sp {
        padding: 1.5rem;
      }

      & + & {
        margin: 40px 0 0;
        position: relative;
        z-index: 1;

        &:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 17.5px 0 17.5px;
          border-color: $main transparent transparent transparent;
          position: absolute;
          bottom: calc(100% + 10px);
          left: 50%;
          z-index: 2;
          transform: translateX(-50%);
        }
      }
    }

    &_pic {
      width: 110px;
      margin: 0 3% 0 0;

      @include sp {
        margin: 0 1rem 0 0;
      }
    }

    &_box {
      flex: 1;
    }

    &_lead {
      padding: 4% 0 0;
      font-size: 16px;
      line-height: 2;
      text-align: center;

      @include sp {
        font-size: 1.3rem;
      }
    }
  }

  &List {
    width: 65%;
    margin: 0 auto;

    @include pc {
      max-width: 646px;
    }

    @include sp {
      width: 100%;
    }

    &_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include sp {
        display: block;
      }

      &Item {
        width: 293px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;

        @include pc {
          &:nth-child(n + 3) {
            margin: 5% 0 0;
          }
        }

        @include sp {
          width: 100%;

          & + & {
            margin: 3rem 0 0;
          }
        }
      }
    }

    &_box {
      width: 100%;
    }

    &_pic {
      width: 100%;
      margin: 8% 0 0;

      @include sp {
        margin: 2rem 0 0;
      }
    }
  }

  &Leader {
    width: 100%;
    margin: 10% auto;

    @include sp {
      margin: 4rem auto;
    }

    &_cont {
      width: 646px;
      margin: 64px auto 0;
      display: flex;
      justify-content: space-between;

      @include sp {
        width: 100%;
        margin: 3rem auto 0;
      }
    }

    &_list {
      width: 293px;

      @include sp {
        width: 48%;
      }
    }
  }

  &Step {
    width: 100%;
    margin: 0 0 15%;

    @include sp {
      margin: 0 0 8rem;
    }

    &_cont {
      width: 727px;
      margin: 4% auto 0;
      padding: 4%;
      background-color: $aquaPale;

      @include sp {
        width: 100%;
        margin: 2rem auto 0;
        padding: 2rem 2.5rem;
      }
    }

    &_inner {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      & + & {
        margin: 8px 0 0;
      }
    }

    &_pic {
      width: 128px;
      margin: 0 20px 0 0;

      @include sp {
        width: 40%;
        margin: 0 1.5rem 0 0;
      }
    }

    &_arrow {
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        width: 22px;
        height: 76px;
        background: url('../img/human/human_step_arrow.svg') no-repeat center;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 100%;
        z-index: 2;
      }
    }

    &_box {
      flex: 1;
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .p-humanList_contItem {
    display: block;
  }
}
