.c-pager {
  margin: 80px 0 0;

  @include sp {
    margin: 40px 0 0;
  }

  &_nav {
    display: flex;
    justify-content: center;

    .page-numbers {
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      background-color: $white;
      border: 3px solid $main;
      border-radius: 50%;
      color: $main;

      @include pc {
        transition: color $anime, background-color $anime;

        &:after {
          transition: border-color $anime;
        }

        &:hover {
          color: $white;
          background-color: $main;

          &:after {
            border-color: $white;
          }
        }
      }

      @include sp {
        width: 28px;
        height: 28px;
        font-size: 12px;
        border-width: 1px;
      }

      & + .page-numbers {
        margin: 0 0 0 12px;
      }

      &.current {
        color: $white;
        background-color: $main;
        pointer-events: none;
      }

      &.prev,
      &.next {
        text-indent: -9999px;
        position: relative;

        &:after {
          content: '';
          width: 12px;
          height: 12px;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);

          @include sp {
            width: 6px;
            height: 6px;
          }
        }
      }

      &.prev {
        &:after {
          left: calc(50% + 3px);
          border-top: 1px solid $main;
          border-left: 1px solid $main;

          @include sp {
            left: calc(50% + 2px);
          }
        }
      }

      &.next {
        &:after {
          left: calc(50% - 3px);
          border-right: 1px solid $main;
          border-bottom: 1px solid $main;

          @include sp {
            left: calc(50% - 2px);
          }
        }
      }
    }
  }
}
