.p-child {
  margin: 0 0 156px;

  @include sp {
    margin: 0 0 80px;
  }

  &Cont {
    margin: 0 0 192px;
    display: flex;
    justify-content: space-between;

    @include sp {
      margin: 0 0 80px;
      display: block;
    }

    &_box {
      width: 47%;

      @include pc {
        max-width: 463px;
      }

      @include sp {
        width: 100%;

        & + & {
          margin: 32px 0 0;
        }
      }
    }

    &_heading {
      margin: 0 0 28px;
      padding: 0 0 16px;
      font-size: 35px;
      font-weight: 500;
      letter-spacing: 0.1em;
      color: $main;
      border-bottom: 2px solid $main;

      @include sp {
        margin: 0 0 16px;
        padding: 0 0 8px;
        font-size: 18px;
      }
    }

    &_list {
      margin: 0 0 12px;
      display: flex;
      justify-content: space-between;

      &Item {
        width: 49%;

        @include pc {
          max-width: 226px;
        }
      }
    }

    &_text {
      font-size: 14px;
      line-height: 1.7;
    }
  }
}
