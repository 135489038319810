@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
