.c-pic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &_list {
    width: 30%;

    @include pc {
      max-width: 293px;

      &:nth-child(n + 4) {
        margin: 5% 0 0;
      }
    }

    @include sp {
      width: 46%;

      &:nth-child(n + 3) {
        margin: 2rem 0 0;
      }
    }
  }
}
