.p-career {
  &Cont {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include sp {
      display: block;
    }

    &_box {
      display: flex;
      flex-flow: column;
      justify-content: center;

      &:first-child {
        @include pc {
          width: 50%;
          max-width: 494px;
          display: block;
        }

        @include sp {
          flex: 1;
          margin: 0 0 32px;
        }
      }

      &:last-child {
        width: 47%;
        margin: 0 0 0 40px;
        padding: 32px 36px;
        background-color: $aquaPale;

        @include pc {
          max-width: 464px;
        }

        @include sp {
          width: 100%;
          margin: 0;
          padding: 24px;
        }
      }
    }

    &_text {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.8;

      &--black {
        color: $black;
      }
    }

    &_pic {
      padding: 32px 0 0;
    }

    &_lead {
      padding: 0 0 8px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;

      @include sp {
        font-size: 18px;
      }
    }

    &_list {
      padding: 12px 0 0;

      &Item {
        display: flex;
      }

      &Ico {
        width: 12px;
        height: 12px;
        margin: 8px 12px 0 0;
        display: block;
        background-color: $main;
      }

      &Text {
        flex: 1;
        font-size: 14px;
        line-height: 2;
      }
    }
  }

  &Media {
    display: flex;
    justify-content: space-between;

    @include sp {
      display: block;
    }

    &_pic {
      width: 36%;
      margin: 0 40px 0 0;

      @include pc {
        max-width: 357px;
      }

      @include sp {
        width: 100%;
        margin: 0 0 32px;
      }
    }

    &_box {
      @include pc {
        flex: 1;
      }

      @include sp {
        width: 100%;
      }
    }

    &_text {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.8;
    }
  }
}
