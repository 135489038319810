.p-qc {
  &_cont {
    width: 100%;
    margin: 52px auto 0;

    @include pc {
      max-width: 840px;
    }

    @include sp {
      margin: 36px auto 0;
    }
  }

  &Flow {
    margin: 52px 0 100px;
    padding: 78px 58px 20px;
    background-color: $aquaPale;

    @include sp {
      margin: 40px 0 64px;
      padding: 56px 24px 24px;
    }

    &_cont {
      padding: 0 0 40px;
      display: flex;
      justify-content: space-between;

      @include sp {
        display: block;
      }
    }

    &_list {
      width: 25%;
      display: flex;
      align-items: center;

      @include pc {
        max-width: 220px;
      }

      @include sp {
        width: 100%;
      }

      &--arrow {
        width: 9%;

        @include pc {
          max-width: 72px;
        }

        @include sp {
          width: 72px;
          margin: 40px auto;
          transform: rotate(90deg);
        }
      }
    }

    &_note {
      display: flex;
      justify-content: flex-end;
      font-size: 14px;

      @include sp {
        font-size: 13px;
      }
    }
  }

  &List {
    width: 100%;
    margin: 64px auto 0;
    padding: 48px 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background-color: $aquaPale;

    @include pc {
      max-width: 627px;
    }

    @include sp {
      margin: 12% auto 0;
      padding: 10% 6%;
    }

    &_cont {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include sp {
        display: block;
      }
    }

    &_item {
      width: 49%;
      display: flex;
      font-size: 14px;

      @include pc {
        &:nth-child(n + 3) {
          margin: 32px 0 0;
        }
      }

      @include sp {
        width: 100%;
        font-size: 13px;

        & + & {
          margin: 5% 0 0;
        }
      }
    }

    &_ico {
      color: $main;
    }
  }
}
