.c-hero {
  width: 100%;

  @include sp {
    width: 100%;
    height: 50vh;

    img {
      display: none;
    }
  }

  &--news {
    @include sp {
      background: url('../img/news/hero_news.jpg') no-repeat center;
      background-size: cover;
    }
  }
}