.c-btn {
  width: 100%;
  display: block;
  text-align: center;
  letter-spacing: 0.1em;
  border: 2px solid;

  @include pc {
    transition: color $anime, background-color $anime;
  }

  @include sp {
    font-size: 16px;
  }

  &--large {
    padding: 12px 0;
    font-size: 20px;

    @include sp {
      font-size: 16px;
    }
  }

  &--round {
    border-radius: 50px;
  }

  &--blue {
    color: $white;
    background-color: $main;
    border-color: $main;

    @include pc {
      &:hover {
        color: $main;
        background-color: $white;
      }
    }
  }
}
