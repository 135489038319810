.c-iv {
  overflow: hidden;

  &Cont {
    width: 90%;
    margin: 100px auto 112px;
    display: flex;
    position: relative;
    z-index: 1;

    @include pc {
      max-width: 840px;
    }

    @include sp {
      width: 100%;
      margin: 64px auto;
      display: block;
    }

    &_label {
      width: 182px;
      padding: 28px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: $white;
      background-color: $main;
      position: absolute;
      top: -30px;
      left: -30px;
      z-index: 2;

      @include sp {
        width: 100%;
        padding: 16px;
        font-size: 16px;
        position: static;
      }
    }

    &_pic {
      width: 50%;
      display: flex;
      align-items: center;

      @include sp {
        width: 100%;
        display: block;
      }
    }

    &_box {
      width: 50%;
      padding: 32px 36px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;

      @include sp {
        width: 100%;
        padding: 20px 24px;
        display: block;
      }

      &--blue {
        background-color: $main;
      }
    }

    &_name {
      padding: 0 0 12px;
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: 0.05em;
      color: $white;
    }

    &_lead {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.8;
      color: $white;
    }

    &_link {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      bottom: 32px;
      left: 60%;

      @include pc {
        transition: opacity $anime;

        .c-ivCont_linkArrow {
          transition: width $anime;
        }

        &:hover {
          opacity: 0.7;

          .c-ivCont_linkArrow {
            width: 66px;
          }
        }
      }

      @include sp {
        padding: 32px 0 0;
        position: static;
      }

      &Inner {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $white;

        @include sp {
          font-size: 14px;
        }
      }

      &Arrow {
        width: 32px;
        height: 1px;
        margin: 0 0 0 12px;
        background-color: $white;
        position: relative;
        z-index: 1;
        transform-origin: left;

        &:after {
          content: '';
          width: 8px;
          height: 8px;
          border-right: 1px solid $white;
          border-bottom: 1px solid $white;
          position: absolute;
          top: calc(50% - 0.5px);
          right: 0;
          z-index: 2;
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }
}
