@charset "UTF-8";


/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
img {
  vertical-align: bottom;
}

@media screen and (max-width: 767px) {
  html {
    font-size: calc(100vw / 32);
  }
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  overflow-x: hidden;
}

@media screen and (max-width: 767px) {
  body.is-fixed {
    overflow: hidden;
  }
}

img {
  width: 100%;
  height: auto;
  display: block;
}

main {
  margin: 150px 0 0;
  display: block;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  main {
    margin: 98px 0 0;
  }
}

@media screen and (max-width: 767px) {
  main {
    margin: 83px 0 0;
  }
}

@media screen and (min-width: 768px) {
  .pc {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.l-header {
  width: 100%;
  height: 150px;
  padding: 20px 0;
  display: flex;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9990;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .l-header {
    height: 98px;
    padding: 16px 0;
  }
}

@media screen and (max-width: 767px) {
  .l-header {
    height: 83px;
    padding: 12px 0;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  }
}

.l-header_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-header_cont {
    height: 100%;
  }
}

.l-header_logoItem {
  display: block;
  color: #323232;
}

@media screen and (min-width: 768px) {
  .l-header_logoItem {
    transition: opacity 0.3s ease;
  }
  .l-header_logoItem:hover {
    opacity: 0.7;
  }
}

.l-header_logoText {
  padding: 0 0 8px;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .l-header_logoText {
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_logoText {
    font-size: 10px;
  }
}

.l-header_logoImg {
  width: 150px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .l-header_logoImg {
    width: 100px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_logoImg {
    width: 80px;
  }
}

.l-header_nav {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .l-header_nav {
    margin: 20px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .l-header_nav {
    width: 100vw;
    height: calc(100vh - 83px);
    padding: 0 0 48px;
    background-color: #fff;
    position: fixed;
    top: 83px;
    left: 0;
    z-index: -9998;
    pointer-events: none;
    opacity: 0;
    overflow-y: scroll;
  }
}

.l-header_nav.is-view {
  z-index: 9998;
  pointer-events: auto;
  opacity: 1;
  transition: opacity 0.3s;
}

.l-header_btn {
  width: 92px;
  position: absolute;
  top: -20px;
  right: 0;
}

@media screen and (max-width: 767px) {
  .l-header_btn {
    width: 90%;
    margin: 32px auto 60px;
    position: static;
  }
}

.l-header_btnItem {
  width: 100%;
  height: 100%;
  padding: 8px 0;
  display: block;
  font-size: 14px;
  line-height: 1.6;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
  background-color: #37ade0;
  border: 2px solid #37ade0;
  border-top: none;
  border-radius: 0 0 6px 6px;
}

@media screen and (min-width: 768px) {
  .l-header_btnItem {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .l-header_btnItem:hover {
    color: #37ade0;
    background-color: #fff;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .l-header_btnItem {
    padding: 4px 0;
    top: -16px;
  }
}

@media screen and (max-width: 767px) {
  .l-header_btnItem {
    padding: 12px 0;
    font-size: 1.3rem;
    border-radius: 0;
  }
}

.l-headerHamburger {
  display: none;
}

@media screen and (max-width: 767px) {
  .l-headerHamburger {
    width: 40px;
    height: 24px;
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 9999;
    transform: translateY(-50%);
  }
}

.l-headerHamburger.is-active .l-headerHamburger_line--top {
  width: 90%;
  top: 50%;
  transform: translate(-50%, 50%) rotate(-45deg);
}

.l-headerHamburger.is-active .l-headerHamburger_line--mid {
  opacity: 0;
}

.l-headerHamburger.is-active .l-headerHamburger_line--btm {
  width: 90%;
  top: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
}

.l-headerHamburger_line, .l-headerHamburger_line--top, .l-headerHamburger_line--mid, .l-headerHamburger_line--btm {
  width: 100%;
  height: 6px;
  background-color: #5b5b5b;
  position: absolute;
  left: 50%;
}

@media screen and (max-width: 767px) {
  .l-headerHamburger_line, .l-headerHamburger_line--top, .l-headerHamburger_line--mid, .l-headerHamburger_line--btm {
    height: 3px;
  }
}

.l-headerHamburger_line--top {
  top: 0;
  transform: translateX(-50%);
  transition: width 0.3s, transform 0.3s;
}

.l-headerHamburger_line--mid {
  top: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s;
}

.l-headerHamburger_line--btm {
  top: 100%;
  transform: translateX(-50%);
  transition: width 0.3s, transform 0.3s;
}

.l-headerNav {
  width: 100%;
}

.l-headerNav_cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .l-headerNav_cont {
    display: block;
    border-bottom: 2px solid #fff;
  }
}

.l-headerNav_list {
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-headerNav_list {
    width: 100%;
  }
}

.l-headerNav_list + .l-headerNav_list {
  margin: 0 0 0 40px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .l-headerNav_list + .l-headerNav_list {
    margin: 0 0 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .l-headerNav_list + .l-headerNav_list {
    margin: 0;
    border-top: 2px solid #fff;
  }
}

.l-headerNav_list.is-active .l-headerNav_item {
  color: #37ade0;
}

.l-headerNav_item {
  font-size: 14px;
  color: #000;
}

@media screen and (min-width: 768px) {
  .l-headerNav_item {
    transition: color 0.3s ease;
  }
  .l-headerNav_item:hover {
    color: #37ade0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .l-headerNav_item {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .l-headerNav_item {
    width: 100%;
    padding: 1.2rem 1.5rem;
    box-sizing: border-box;
    display: block;
    font-size: 1.3rem;
    color: #fff;
    background-color: rgba(55, 173, 224, 0.5);
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .l-headerNav_item.pc {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .l-headerNav_item.sp {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .l-headerNav_item.sp:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 767px) {
  .l-headerNav_item.is-active:after {
    border-width: 0 6px 8px 6px;
    border-color: transparent transparent #fff transparent;
  }
}

.l-headerMega {
  padding: 40px 32px 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  position: absolute;
  top: 120%;
  left: 0;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
}

@media screen and (min-width: 768px) {
  .l-headerMega {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.26);
    transition: opacity 0.3s ease;
  }
  .l-headerMega:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 9px 0 9px;
    border-color: #37ade0 transparent transparent transparent;
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  .l-headerMega:after {
    border-width: 12px 8px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .l-headerMega {
    padding: 28px 24px 16px;
  }
}

@media screen and (max-width: 767px) {
  .l-headerMega {
    width: 100%;
    height: 0;
    padding: 0 2rem;
    display: block;
    position: static;
    opacity: 0;
    pointer-events: auto;
    transition: height 0.3s, padding 0.3s, opacity 0.2s ease;
    pointer-events: none;
  }
}

.l-headerMega--human {
  width: 420px;
}

.l-headerMega--technical {
  width: 320px;
}

.l-headerMega.is-view {
  opacity: 1;
  pointer-events: auto;
}

@media screen and (min-width: 768px) {
  .l-headerMega.is-view:after {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .l-headerMega.is-view {
    height: auto;
    padding: 1.5rem 2rem;
  }
}

.l-headerMega_box + .l-headerMega_box {
  margin: 0 0 0 24px;
}

@media screen and (max-width: 767px) {
  .l-headerMega_box + .l-headerMega_box {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 767px) {
  .l-headerMega_list + .l-headerMega_list {
    margin: 24px 0 0;
  }
}

.l-headerMega_link {
  padding: 12px 0;
  display: block;
  font-size: 14px;
  color: #000;
}

@media screen and (min-width: 768px) {
  .l-headerMega_link {
    white-space: nowrap;
    transition: color 0.3s ease;
  }
  .l-headerMega_link:hover {
    color: #37ade0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .l-headerMega_link {
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .l-headerMega_link {
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
  }
  .l-headerMega_link:before {
    content: 'ー';
    display: block;
    margin: 0 8px 0 0;
  }
}

/* Safari用のハックは、Chromeに適用されないようにする */
@supports (-webkit-touch-callout: none) {
  .l-header_nav {
    /* Safari用のハック */
    height: -webkit-fill-available;
  }
}

.l-footer {
  margin: 120px 0 0;
  padding: 60px 0 40px;
  background-color: #e1f3fa;
  position: relative;
}

.l-footer.is-animated .l-footer_btn {
  position: absolute;
  bottom: 83%;
}

@media screen and (max-width: 767px) {
  .l-footer {
    margin: 8rem 0 0;
    padding: 3rem 0 2rem;
  }
}

.l-footer_logo {
  width: 146px;
  margin: 0 auto 88px;
}

@media screen and (max-width: 767px) {
  .l-footer_logo {
    width: 35%;
    margin: 0 auto 4rem;
  }
}

.l-footer_btn {
  width: 80px;
  height: 80px;
  background-color: #37ade0;
  border: 2px solid #37ade0;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 12%;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media screen and (min-width: 768px) {
  .l-footer_btn {
    transition: background-color 0.3s ease, opacity 0.3s ease;
  }
  .l-footer_btn:after {
    transition: border-color 0.3s ease;
  }
  .l-footer_btn:hover {
    background-color: #fff;
  }
  .l-footer_btn:hover:after {
    border-color: #37ade0;
  }
}

@media screen and (max-width: 767px) {
  .l-footer_btn {
    width: 4rem;
    height: 4rem;
    bottom: 2rem;
    right: 2rem;
  }
}

.l-footer_btn:after {
  content: '';
  width: 20px;
  height: 20px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  top: calc(50% + 5px);
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media screen and (max-width: 767px) {
  .l-footer_btn:after {
    width: 8px;
    height: 8px;
    top: calc(50% + 2px);
  }
}

.l-footer_btn.is-view {
  opacity: 1;
}

.l-footer_copy {
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.025em;
}

@media screen and (max-width: 767px) {
  .l-footer_copy {
    font-size: 10px;
  }
}

.l-wrap {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .l-wrap {
    max-width: 1040px;
  }
}

@media screen and (max-width: 767px) {
  .l-wrap {
    width: 90%;
    padding: 0;
  }
}

.l-wrapBox {
  width: 72%;
  margin: -40px auto 0;
  padding: 8%;
  background-color: #fff;
  position: relative;
}

@media screen and (min-width: 768px) {
  .l-wrapBox {
    max-width: 722px;
  }
}

@media screen and (max-width: 767px) {
  .l-wrapBox {
    width: 95%;
    padding: 8% 4%;
  }
}

.l-wrapBox--large {
  width: 84%;
}

@media screen and (min-width: 768px) {
  .l-wrapBox--large {
    max-width: 840px;
  }
}

.l-wrapSide {
  width: 22%;
  padding: 4%;
  background-color: #fff;
  border: 1px solid #37ade0;
  border-radius: 12px;
  position: absolute;
  top: 92px;
  right: 0;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .l-wrapSide {
    max-width: 220px;
  }
}

@media screen and (max-width: 767px) {
  .l-wrapSide {
    width: 95%;
    margin: 40px auto;
    padding: 6%;
    position: static;
  }
}

.l-wrapFull {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

@media screen and (min-width: 768px) {
  .l-wrapFull {
    max-width: 1406px;
  }
}

@media screen and (max-width: 767px) {
  .l-wrapFull {
    width: 100%;
    padding: 0;
  }
}

.c-bnr_cont {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .c-bnr_cont {
    padding: 0 8%;
    display: block;
  }
}

.c-bnr_list {
  width: 45%;
}

@media screen and (min-width: 768px) {
  .c-bnr_list {
    max-width: 450px;
  }
}

@media screen and (max-width: 767px) {
  .c-bnr_list {
    width: 100%;
  }
  .c-bnr_list + .c-bnr_list {
    margin: 32px 0 0;
  }
}

.c-bnr_link {
  width: 100%;
  height: 100%;
  padding: 84px 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .c-bnr_link:before {
    transition: background-color 0.3s ease;
  }
  .c-bnr_link:after {
    transition: transform 0.4s ease;
  }
  .c-bnr_link:hover:before {
    background-color: rgba(0, 0, 0, 0);
  }
  .c-bnr_link:hover:after {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 767px) {
  .c-bnr_link {
    padding: 56px 0;
  }
}

.c-bnr_link:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.c-bnr_link:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
  z-index: -2;
}

.c-bnr_link--recruit:after {
  background: url("../img/common/site_recruit_pic.jpg") no-repeat center;
  background-size: cover;
}

.c-bnr_link--group:after {
  background: url("../img/common/site_group_pic.jpg") no-repeat center;
  background-size: cover;
}

.c-bnr_logo {
  width: 98px;
  margin: 0 0 12px;
}

@media screen and (max-width: 767px) {
  .c-bnr_logo {
    width: 72px;
  }
}

.c-bnr_lead {
  margin: 0 0 16px;
  padding: 0 0 16px;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0.1em;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-bnr_lead {
    font-size: 1.6rem;
  }
}

.c-bnr_lead:after {
  content: '';
  width: 48px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 767px) {
  .c-bnr_lead:after {
    width: 2.8rem;
  }
}

.c-bnr_text {
  font-size: 25px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .c-bnr_text {
    font-size: 1.3rem;
    letter-spacing: 0.02em;
  }
}

.c-box {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .c-box {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  }
}

.c-box:after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 21px 21px 0 0;
  border-color: #37ade0 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-box:after {
    border-width: 42px 42px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .c-box:after {
    border-width: 21px 21px 0 0;
  }
}

.c-btn {
  width: 100%;
  display: block;
  text-align: center;
  letter-spacing: 0.1em;
  border: 2px solid;
}

@media screen and (min-width: 768px) {
  .c-btn {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
}

@media screen and (max-width: 767px) {
  .c-btn {
    font-size: 16px;
  }
}

.c-btn--large {
  padding: 12px 0;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .c-btn--large {
    font-size: 16px;
  }
}

.c-btn--round {
  border-radius: 50px;
}

.c-btn--blue {
  color: #fff;
  background-color: #37ade0;
  border-color: #37ade0;
}

@media screen and (min-width: 768px) {
  .c-btn--blue:hover {
    color: #37ade0;
    background-color: #fff;
  }
}

.c-heading {
  padding: 0 0 36px;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.12em;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .c-heading {
    padding: 0 0 28px;
    font-size: 1.7rem;
  }
}

.c-heading_sub {
  padding: 16px 0 0;
  display: block;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 767px) {
  .c-heading_sub {
    padding: 8px 0 0;
    font-size: 1.3rem;
  }
}

.c-heading_center {
  padding: 0 0 28px;
  display: flex;
  justify-content: center;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .c-heading_center {
    padding: 0 0 16px;
  }
}

.c-heading_centerInner {
  display: inline-block;
  font-size: 35px;
  font-weight: 500;
  line-height: 2.4;
  border-bottom: 4px solid #37ade0;
}

@media screen and (max-width: 767px) {
  .c-heading_centerInner {
    font-size: 1.6rem;
    line-height: 1.7;
    border-width: 2px;
  }
}

.c-headingBox--btm {
  margin: 0 0 148px;
}

@media screen and (max-width: 767px) {
  .c-headingBox--btm {
    margin: 0 0 64px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .c-headingBox--btm {
    margin: 0 0 4rem;
  }
}

.c-headingBox_lower, .c-headingBox_lower--inner {
  margin: 0 0 32px;
  padding: 16px 0;
  display: flex;
  align-items: flex-end;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.6;
  color: #37ade0;
  border-bottom: 2px solid #37ade0;
}

@media screen and (max-width: 767px) {
  .c-headingBox_lower, .c-headingBox_lower--inner {
    margin: 0 0 24px;
    padding: 8px 0;
    display: block;
    font-size: 1.6rem;
  }
}

.c-headingBox_lowerInner {
  padding: 0 0 0 36px;
  display: block;
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .c-headingBox_lowerInner {
    padding: 0;
    font-size: 14px;
  }
}

.c-headingBox_lower--inner {
  margin: 0 0 3%;
  padding: 0 0 1%;
}

@media screen and (max-width: 767px) {
  .c-headingBox_lower--inner {
    margin: 0 0 1rem;
    padding: 0 0 1rem;
  }
}

.c-headingBox_text {
  font-size: 14px;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .c-headingBox_text {
    font-size: 1.2rem;
  }
}

.c-headingCenter {
  margin: 0 0 40px;
}

@media screen and (max-width: 767px) {
  .c-headingCenter {
    padding: 0 5%;
  }
}

.c-headingCenter_text {
  font-size: 16px;
  line-height: 2;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-headingCenter_text {
    font-size: 1.2rem;
    text-align: left;
  }
}

.c-hero {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .c-hero {
    width: 100%;
    height: 50vh;
  }
  .c-hero img {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .c-hero--news {
    background: url("../img/news/hero_news.jpg") no-repeat center;
    background-size: cover;
  }
}

.c-intro {
  width: 100%;
  margin: 0 auto 340px;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .c-intro {
    max-width: 840px;
  }
}

@media screen and (max-width: 767px) {
  .c-intro {
    margin: 40px auto 80px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.c-intro_box {
  width: 54%;
  margin: -32px 0 0;
  padding: 88px 60px 72px;
  background-color: #37ade0;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .c-intro_box {
    max-width: 452px;
  }
}

@media screen and (max-width: 767px) {
  .c-intro_box {
    width: 100%;
    padding: 72px 40px 52px;
    order: 1;
  }
}

.c-intro_lead, .c-intro_text {
  color: #fff;
}

.c-intro_lead {
  padding: 0 0 24px;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.4;
}

@media screen and (max-width: 767px) {
  .c-intro_lead {
    margin: 0 0 16px;
    padding: 0 0 12px;
    font-size: 1.6rem;
    text-align: center;
    position: relative;
  }
  .c-intro_lead:after {
    content: '';
    width: 60px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.c-intro_text {
  font-size: 14px;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .c-intro_text {
    font-size: 1.2rem;
  }
}

.c-intro_pic {
  width: 52%;
  position: absolute;
  top: 184px;
  right: 0;
  z-index: 0;
}

@media screen and (min-width: 768px) {
  .c-intro_pic {
    max-width: 434px;
  }
}

@media screen and (max-width: 767px) {
  .c-intro_pic {
    width: 80%;
    order: 0;
    position: relative;
    top: auto;
    right: auto;
    z-index: 2;
  }
}

.c-introScroll {
  position: absolute;
  top: -48px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}

@media screen and (max-width: 767px) {
  .c-introScroll {
    display: none;
  }
}

.c-introScroll_textInner {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  transform: rotate(90deg);
}

.c-introScroll_bar {
  width: 2px;
  height: 55px;
  margin: 6px auto 0;
  display: block;
  background: linear-gradient(to bottom, white 50%, rgba(255, 255, 255, 0) 50%);
  background-position: 0 -60px;
  background-size: 100% 200%;
  animation: scrolldown 2.2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
}

@media screen and (max-width: 767px) {
  .c-introScroll_bar {
    height: 63px;
  }
}

@keyframes scrolldown {
  0% {
    background-position: 0 -55px;
  }
  75% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 55px;
  }
}

.c-iv {
  overflow: hidden;
}

.c-ivCont {
  width: 90%;
  margin: 100px auto 112px;
  display: flex;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .c-ivCont {
    max-width: 840px;
  }
}

@media screen and (max-width: 767px) {
  .c-ivCont {
    width: 100%;
    margin: 64px auto;
    display: block;
  }
}

.c-ivCont_label {
  width: 182px;
  padding: 28px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #37ade0;
  position: absolute;
  top: -30px;
  left: -30px;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .c-ivCont_label {
    width: 100%;
    padding: 16px;
    font-size: 16px;
    position: static;
  }
}

.c-ivCont_pic {
  width: 50%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-ivCont_pic {
    width: 100%;
    display: block;
  }
}

.c-ivCont_box {
  width: 50%;
  padding: 32px 36px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-ivCont_box {
    width: 100%;
    padding: 20px 24px;
    display: block;
  }
}

.c-ivCont_box--blue {
  background-color: #37ade0;
}

.c-ivCont_name {
  padding: 0 0 12px;
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.05em;
  color: #fff;
}

.c-ivCont_lead {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.8;
  color: #fff;
}

.c-ivCont_link {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 32px;
  left: 60%;
}

@media screen and (min-width: 768px) {
  .c-ivCont_link {
    transition: opacity 0.3s ease;
  }
  .c-ivCont_link .c-ivCont_linkArrow {
    transition: width 0.3s ease;
  }
  .c-ivCont_link:hover {
    opacity: 0.7;
  }
  .c-ivCont_link:hover .c-ivCont_linkArrow {
    width: 66px;
  }
}

@media screen and (max-width: 767px) {
  .c-ivCont_link {
    padding: 32px 0 0;
    position: static;
  }
}

.c-ivCont_linkInner {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .c-ivCont_linkInner {
    font-size: 14px;
  }
}

.c-ivCont_linkArrow {
  width: 32px;
  height: 1px;
  margin: 0 0 0 12px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  transform-origin: left;
}

.c-ivCont_linkArrow:after {
  content: '';
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  position: absolute;
  top: calc(50% - 0.5px);
  right: 0;
  z-index: 2;
  transform: translateY(-50%) rotate(-45deg);
}

.c-kv {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 767px) {
  .c-kv_hero--human {
    width: 100%;
    height: 35vh;
    background: url("../img/human/human_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .c-kv_hero--conference {
    width: 100%;
    height: 35vh;
    background: url("../img/conference/conference_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .c-kv_hero--technical {
    width: 100%;
    height: 35vh;
    background: url("../img/technical/technical_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .c-kv_hero--qualification {
    width: 100%;
    height: 35vh;
    background: url("../img/qualification/qualification_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .c-kv_hero--qualification--interview {
    width: 100%;
    height: 35vh;
    background: url("../img/qualification/qualification_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .c-kv_hero--learning {
    width: 100%;
    height: 35vh;
    background: url("../img/learning/learning_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .c-kv_hero--learning--interview {
    width: 100%;
    height: 35vh;
    background: url("../img/learning/interview/interview_learning_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .c-kv_hero--reception {
    width: 100%;
    height: 35vh;
    background: url("../img/reception/reception_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

.c-kv_heading {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.1em;
  color: #37ade0;
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);
  white-space: nowrap;
}

@media screen and (min-width: 768px) {
  .c-kv_heading {
    border-bottom: 3px solid #37ade0;
  }
}

@media screen and (max-width: 767px) {
  .c-kv_heading {
    padding: 8px 32px;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
    background-color: #37ade0;
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 767px) {
  .c-kv img {
    display: none;
  }
}

.c-label {
  padding: 6px 8px;
  display: inline-block;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .c-label {
    padding: 4px 6px;
    font-size: 12px;
  }
}

.c-label--gray {
  color: #fff;
  background-color: #919191;
}

.c-label--heading {
  padding: 8px 36px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.12em;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .c-label--heading {
    font-size: 1.1rem;
  }
}

.c-label--heading--aqua {
  background-color: #37ade0;
}

.c-labelWrap {
  padding: 4px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-linkInner {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 768px) {
  .c-linkInner {
    transition: opacity 0.3s ease;
  }
  .c-linkInner:hover {
    opacity: 0.7;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-linkInner {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .c-linkInner {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .c-linkInner {
    font-size: 1.2rem;
  }
}

.c-linkInner--blue {
  color: #37ade0;
}

@media screen and (min-width: 768px) {
  .c-linkInner--blue .c-linkInner_arrow {
    background-color: #37ade0;
  }
  .c-linkInner--blue .c-linkInner_arrow:after {
    border-color: transparent transparent transparent #37ade0;
  }
}

.c-linkInner--black {
  color: #323232;
}

@media screen and (max-width: 767px) {
  .c-linkInner--black {
    color: #37ade0;
  }
}

@media screen and (min-width: 768px) {
  .c-linkInner--black .c-linkInner_arrow {
    background-color: #323232;
  }
  .c-linkInner--black .c-linkInner_arrow:after {
    border-color: transparent transparent transparent #323232;
  }
}

.c-linkInner_arrow {
  width: 68px;
  height: 1px;
  margin: 0 0 0 16px;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-linkInner_arrow {
    width: 16px;
    height: 16px;
    margin: 4px 0 0;
    box-sizing: border-box;
    border-right: 3px solid #37ade0;
    border-bottom: 3px solid #37ade0;
    transform: rotate(45deg);
  }
}

@media screen and (max-width: 767px) {
  .c-linkInner_arrow {
    width: 1rem;
    height: 1rem;
    margin: 0.5rem 0 0;
    box-sizing: border-box;
    border-right: 2px solid #37ade0;
    border-bottom: 2px solid #37ade0;
    transform: rotate(45deg);
  }
}

.c-linkInner_arrow:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #37ade0;
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translateY(-50%);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .c-linkInner_arrow:after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .c-linkInner_arrow:after {
    display: none;
  }
}

.c-pager {
  margin: 80px 0 0;
}

@media screen and (max-width: 767px) {
  .c-pager {
    margin: 40px 0 0;
  }
}

.c-pager_nav {
  display: flex;
  justify-content: center;
}

.c-pager_nav .page-numbers {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: #fff;
  border: 3px solid #37ade0;
  border-radius: 50%;
  color: #37ade0;
}

@media screen and (min-width: 768px) {
  .c-pager_nav .page-numbers {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .c-pager_nav .page-numbers:after {
    transition: border-color 0.3s ease;
  }
  .c-pager_nav .page-numbers:hover {
    color: #fff;
    background-color: #37ade0;
  }
  .c-pager_nav .page-numbers:hover:after {
    border-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .c-pager_nav .page-numbers {
    width: 28px;
    height: 28px;
    font-size: 12px;
    border-width: 1px;
  }
}

.c-pager_nav .page-numbers + .page-numbers {
  margin: 0 0 0 12px;
}

.c-pager_nav .page-numbers.current {
  color: #fff;
  background-color: #37ade0;
  pointer-events: none;
}

.c-pager_nav .page-numbers.prev, .c-pager_nav .page-numbers.next {
  text-indent: -9999px;
  position: relative;
}

.c-pager_nav .page-numbers.prev:after, .c-pager_nav .page-numbers.next:after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media screen and (max-width: 767px) {
  .c-pager_nav .page-numbers.prev:after, .c-pager_nav .page-numbers.next:after {
    width: 6px;
    height: 6px;
  }
}

.c-pager_nav .page-numbers.prev:after {
  left: calc(50% + 3px);
  border-top: 1px solid #37ade0;
  border-left: 1px solid #37ade0;
}

@media screen and (max-width: 767px) {
  .c-pager_nav .page-numbers.prev:after {
    left: calc(50% + 2px);
  }
}

.c-pager_nav .page-numbers.next:after {
  left: calc(50% - 3px);
  border-right: 1px solid #37ade0;
  border-bottom: 1px solid #37ade0;
}

@media screen and (max-width: 767px) {
  .c-pager_nav .page-numbers.next:after {
    left: calc(50% - 2px);
  }
}

.c-pic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-pic_list {
  width: 30%;
}

@media screen and (min-width: 768px) {
  .c-pic_list {
    max-width: 293px;
  }
  .c-pic_list:nth-child(n + 4) {
    margin: 5% 0 0;
  }
}

@media screen and (max-width: 767px) {
  .c-pic_list {
    width: 46%;
  }
  .c-pic_list:nth-child(n + 3) {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 767px) {
  .c-side {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  .c-side_cont {
    width: 48%;
  }
}

@media screen and (min-width: 768px) {
  .c-side_cont + .c-side_cont {
    margin: 36px 0 0;
  }
}

.c-side_heading {
  padding: 0 0 12px;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.12em;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .c-side_heading {
    padding: 0 0 12px;
    font-size: 18px;
  }
}

.c-sideBox li {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.c-sideBox li + li {
  margin-top: 10px;
}

.c-sideBox li a {
  display: block;
  font-size: 14px;
  color: #000;
}

@media screen and (min-width: 768px) {
  .c-sideBox li a {
    transition: color 0.3s ease;
  }
  .c-sideBox li a:hover {
    color: #37ade0;
  }
}

.c-text, .c-text--center, .c-text--center--pc, .c-text--center--sp, .c-text--indent {
  font-size: 14px;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .c-text, .c-text--center, .c-text--center--pc, .c-text--center--sp, .c-text--indent {
    font-size: 1.2rem;
  }
}

.c-text--center {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .c-text--center--pc {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .c-text--center--sp {
    text-align: center;
  }
}

.c-text--indent {
  padding: 0 0 0 1em;
  text-indent: -1em;
}

.p-care {
  margin: 0 0 12%;
}

@media screen and (max-width: 767px) {
  .p-care {
    margin: 0 0 6rem;
  }
}

.p-careCard {
  margin: 0 0 200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .p-careCard:after {
    content: '';
    width: 28%;
  }
}

@media screen and (max-width: 767px) {
  .p-careCard {
    margin: 0 0 64px;
  }
}

.p-careCard_list {
  width: 28%;
}

@media screen and (min-width: 768px) {
  .p-careCard_list {
    max-width: 280px;
  }
  .p-careCard_list:nth-child(n + 4) {
    margin: 88px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .p-careCard_list {
    width: 46%;
  }
  .p-careCard_list:nth-child(n + 3) {
    margin: 32px 0 0;
  }
}

.p-careCard_pic {
  margin: 0 0 8px;
}

@media screen and (max-width: 767px) {
  .p-careCard_pic {
    margin: 0 0 4px;
  }
}

.p-careCard_text {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .p-careCard_text {
    font-size: 14px;
  }
}

.p-careerCont {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .p-careerCont {
    display: block;
  }
}

.p-careerCont_box {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .p-careerCont_box:first-child {
    width: 50%;
    max-width: 494px;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .p-careerCont_box:first-child {
    flex: 1;
    margin: 0 0 32px;
  }
}

.p-careerCont_box:last-child {
  width: 47%;
  margin: 0 0 0 40px;
  padding: 32px 36px;
  background-color: #e1f3fa;
}

@media screen and (min-width: 768px) {
  .p-careerCont_box:last-child {
    max-width: 464px;
  }
}

@media screen and (max-width: 767px) {
  .p-careerCont_box:last-child {
    width: 100%;
    margin: 0;
    padding: 24px;
  }
}

.p-careerCont_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
}

.p-careerCont_text--black {
  color: #000;
}

.p-careerCont_pic {
  padding: 32px 0 0;
}

.p-careerCont_lead {
  padding: 0 0 8px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .p-careerCont_lead {
    font-size: 18px;
  }
}

.p-careerCont_list {
  padding: 12px 0 0;
}

.p-careerCont_listItem {
  display: flex;
}

.p-careerCont_listIco {
  width: 12px;
  height: 12px;
  margin: 8px 12px 0 0;
  display: block;
  background-color: #37ade0;
}

.p-careerCont_listText {
  flex: 1;
  font-size: 14px;
  line-height: 2;
}

.p-careerMedia {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-careerMedia {
    display: block;
  }
}

.p-careerMedia_pic {
  width: 36%;
  margin: 0 40px 0 0;
}

@media screen and (min-width: 768px) {
  .p-careerMedia_pic {
    max-width: 357px;
  }
}

@media screen and (max-width: 767px) {
  .p-careerMedia_pic {
    width: 100%;
    margin: 0 0 32px;
  }
}

@media screen and (min-width: 768px) {
  .p-careerMedia_box {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-careerMedia_box {
    width: 100%;
  }
}

.p-careerMedia_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
}

.p-child {
  margin: 0 0 156px;
}

@media screen and (max-width: 767px) {
  .p-child {
    margin: 0 0 80px;
  }
}

.p-childCont {
  margin: 0 0 192px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-childCont {
    margin: 0 0 80px;
    display: block;
  }
}

.p-childCont_box {
  width: 47%;
}

@media screen and (min-width: 768px) {
  .p-childCont_box {
    max-width: 463px;
  }
}

@media screen and (max-width: 767px) {
  .p-childCont_box {
    width: 100%;
  }
  .p-childCont_box + .p-childCont_box {
    margin: 32px 0 0;
  }
}

.p-childCont_heading {
  margin: 0 0 28px;
  padding: 0 0 16px;
  font-size: 35px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #37ade0;
  border-bottom: 2px solid #37ade0;
}

@media screen and (max-width: 767px) {
  .p-childCont_heading {
    margin: 0 0 16px;
    padding: 0 0 8px;
    font-size: 18px;
  }
}

.p-childCont_list {
  margin: 0 0 12px;
  display: flex;
  justify-content: space-between;
}

.p-childCont_listItem {
  width: 49%;
}

@media screen and (min-width: 768px) {
  .p-childCont_listItem {
    max-width: 226px;
  }
}

.p-childCont_text {
  font-size: 14px;
  line-height: 1.7;
}

.p-conf {
  margin: 0 0 48px;
}

@media screen and (max-width: 767px) {
  .p-conf {
    margin: 0 0 32px;
  }
}

.p-conf_text {
  font-size: 16px;
  line-height: 2;
  text-align: center;
  letter-spacing: 0.025em;
}

@media screen and (max-width: 767px) {
  .p-conf_text {
    font-size: 1.2rem;
    text-align: left;
  }
}

.p-conf_list {
  margin: 0 0 160px;
}

@media screen and (max-width: 767px) {
  .p-conf_list {
    margin: 0 0 80px;
  }
}

.p-human {
  padding: 10% 0;
}

@media screen and (max-width: 767px) {
  .p-human {
    padding: 4rem 0;
  }
}

.p-humanIntro {
  width: 100%;
}

.p-humanMessage_pic {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .p-humanMessage_pic {
    max-width: 840px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanMessage_pic {
    width: 90%;
  }
}

.p-humanMessage_cont {
  width: 100%;
  margin: -5% 0 0;
  padding: 8% 0;
  background-color: #e1f3fa;
}

@media screen and (max-width: 767px) {
  .p-humanMessage_cont {
    margin: -2.5rem 0 0;
    padding: 5rem 0;
  }
}

.p-humanMessage_inner {
  width: 540px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-humanMessage_inner {
    width: 90%;
  }
}

.p-humanMessage_box + .p-humanMessage_box {
  margin: 28px 0 0;
}

@media screen and (max-width: 767px) {
  .p-humanMessage_box + .p-humanMessage_box {
    margin: 2rem 0 0;
  }
}

.p-humanJob {
  padding: 10% 0;
}

@media screen and (max-width: 767px) {
  .p-humanJob {
    padding: 6rem 0;
  }
}

.p-humanJob_pic {
  width: 617px;
  margin: 5% auto 0;
}

@media screen and (max-width: 767px) {
  .p-humanJob_pic {
    width: 95%;
    margin: 2rem auto 0;
  }
}

.p-humanDetail {
  width: 100%;
  margin: 4% 0 12%;
}

@media screen and (max-width: 767px) {
  .p-humanDetail {
    margin: 3rem 0 8rem;
  }
}

.p-humanDetail_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-humanDetail_cont {
    display: block;
  }
}

.p-humanDetail_cont + .p-humanDetail_cont {
  margin: 6% 0 0;
}

@media screen and (max-width: 767px) {
  .p-humanDetail_cont + .p-humanDetail_cont {
    margin: 4rem 0 0;
  }
}

.p-humanDetail_label, .p-humanDetail_label--green, .p-humanDetail_label--pink, .p-humanDetail_label--blue {
  width: 36%;
  margin: 0 2% 0 0;
  padding: 12px 0;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .p-humanDetail_label, .p-humanDetail_label--green, .p-humanDetail_label--pink, .p-humanDetail_label--blue {
    max-width: 356px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-humanDetail_label, .p-humanDetail_label--green, .p-humanDetail_label--pink, .p-humanDetail_label--blue {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanDetail_label, .p-humanDetail_label--green, .p-humanDetail_label--pink, .p-humanDetail_label--blue {
    width: 100%;
    margin: 0 0 1rem;
    padding: 1rem 0;
    font-size: 1.8rem;
  }
}

.p-humanDetail_label--green {
  background-color: #00be96;
}

.p-humanDetail_label--pink {
  background-color: #ffa0a0;
}

.p-humanDetail_label--blue {
  background-color: #6e8cff;
}

@media screen and (min-width: 768px) {
  .p-humanDetail_inner {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .p-humanDetail_inner {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
}

@media screen and (min-width: 768px) {
  .p-humanDetail_text {
    flex: 1;
  }
}

.p-humanDetail_btn {
  width: 64px;
  margin: 0 0 0 2%;
  display: block;
}

@media screen and (min-width: 768px) {
  .p-humanDetail_btn .p-humanDetail_btnCircle, .p-humanDetail_btn .p-humanDetail_btnCircle--green, .p-humanDetail_btn .p-humanDetail_btnCircle--pink, .p-humanDetail_btn .p-humanDetail_btnCircle--blue {
    transition: background-color 0.3s ease;
  }
  .p-humanDetail_btn .p-humanDetail_btnCircle:before, .p-humanDetail_btn .p-humanDetail_btnCircle--green:before, .p-humanDetail_btn .p-humanDetail_btnCircle--pink:before, .p-humanDetail_btn .p-humanDetail_btnCircle--blue:before, .p-humanDetail_btn .p-humanDetail_btnCircle:after, .p-humanDetail_btn .p-humanDetail_btnCircle--green:after, .p-humanDetail_btn .p-humanDetail_btnCircle--pink:after, .p-humanDetail_btn .p-humanDetail_btnCircle--blue:after {
    transition: background-color 0.3s ease;
  }
  .p-humanDetail_btn:hover .p-humanDetail_btnCircle--green {
    background-color: #00be96;
  }
  .p-humanDetail_btn:hover .p-humanDetail_btnCircle--green:before, .p-humanDetail_btn:hover .p-humanDetail_btnCircle--green:after {
    background-color: #fff;
  }
  .p-humanDetail_btn:hover .p-humanDetail_btnCircle--pink {
    background-color: #ffa0a0;
  }
  .p-humanDetail_btn:hover .p-humanDetail_btnCircle--pink:before, .p-humanDetail_btn:hover .p-humanDetail_btnCircle--pink:after {
    background-color: #fff;
  }
  .p-humanDetail_btn:hover .p-humanDetail_btnCircle--blue {
    background-color: #6e8cff;
  }
  .p-humanDetail_btn:hover .p-humanDetail_btnCircle--blue:before, .p-humanDetail_btn:hover .p-humanDetail_btnCircle--blue:after {
    background-color: #fff;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-humanDetail_btn {
    width: 56px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanDetail_btn {
    width: 6rem;
    margin: 2rem 0 0;
  }
}

.p-humanDetail_btnCircle, .p-humanDetail_btnCircle--green, .p-humanDetail_btnCircle--pink, .p-humanDetail_btnCircle--blue {
  width: 64px;
  height: 64px;
  display: block;
  background-color: #fff;
  border: 1px solid;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-humanDetail_btnCircle, .p-humanDetail_btnCircle--green, .p-humanDetail_btnCircle--pink, .p-humanDetail_btnCircle--blue {
    width: 56px;
    height: 56px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanDetail_btnCircle, .p-humanDetail_btnCircle--green, .p-humanDetail_btnCircle--pink, .p-humanDetail_btnCircle--blue {
    width: 4rem;
    height: 4rem;
    margin: 0 auto;
  }
}

.p-humanDetail_btnCircle:before, .p-humanDetail_btnCircle--green:before, .p-humanDetail_btnCircle--pink:before, .p-humanDetail_btnCircle--blue:before, .p-humanDetail_btnCircle:after, .p-humanDetail_btnCircle--green:after, .p-humanDetail_btnCircle--pink:after, .p-humanDetail_btnCircle--blue:after {
  content: '';
  width: 32px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-humanDetail_btnCircle:before, .p-humanDetail_btnCircle--green:before, .p-humanDetail_btnCircle--pink:before, .p-humanDetail_btnCircle--blue:before, .p-humanDetail_btnCircle:after, .p-humanDetail_btnCircle--green:after, .p-humanDetail_btnCircle--pink:after, .p-humanDetail_btnCircle--blue:after {
    width: 24px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanDetail_btnCircle:before, .p-humanDetail_btnCircle--green:before, .p-humanDetail_btnCircle--pink:before, .p-humanDetail_btnCircle--blue:before, .p-humanDetail_btnCircle:after, .p-humanDetail_btnCircle--green:after, .p-humanDetail_btnCircle--pink:after, .p-humanDetail_btnCircle--blue:after {
    width: 2rem;
  }
}

.p-humanDetail_btnCircle:before, .p-humanDetail_btnCircle--green:before, .p-humanDetail_btnCircle--pink:before, .p-humanDetail_btnCircle--blue:before {
  transform: translate(-50%, -50%);
}

.p-humanDetail_btnCircle:after, .p-humanDetail_btnCircle--green:after, .p-humanDetail_btnCircle--pink:after, .p-humanDetail_btnCircle--blue:after {
  transform: translate(-50%, -50%) rotate(-90deg);
}

.p-humanDetail_btnCircle--green {
  border-color: #00be96;
}

.p-humanDetail_btnCircle--green:before, .p-humanDetail_btnCircle--green:after {
  background-color: #00be96;
}

.p-humanDetail_btnCircle--pink {
  border-color: #ffa0a0;
}

.p-humanDetail_btnCircle--pink:before, .p-humanDetail_btnCircle--pink:after {
  background-color: #ffa0a0;
}

.p-humanDetail_btnCircle--blue {
  border-color: #6e8cff;
}

.p-humanDetail_btnCircle--blue:before, .p-humanDetail_btnCircle--blue:after {
  background-color: #6e8cff;
}

.p-humanDetail_btnText, .p-humanDetail_btnText--green, .p-humanDetail_btnText--pink, .p-humanDetail_btnText--blue {
  padding: 8px 0 0;
  display: block;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-humanDetail_btnText, .p-humanDetail_btnText--green, .p-humanDetail_btnText--pink, .p-humanDetail_btnText--blue {
    font-size: 10px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanDetail_btnText, .p-humanDetail_btnText--green, .p-humanDetail_btnText--pink, .p-humanDetail_btnText--blue {
    font-size: 1rem;
  }
}

.p-humanDetail_btnText--green {
  color: #00be96;
}

.p-humanDetail_btnText--pink {
  color: #ffa0a0;
}

.p-humanDetail_btnText--blue {
  color: #6e8cff;
}

.p-humanAbout {
  width: 84%;
  margin: 12% auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .p-humanAbout {
    max-width: 837px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanAbout {
    width: 100%;
    margin: 5rem auto;
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .p-humanAbout_box {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-humanAbout_box {
    width: 100%;
  }
}

.p-humanAbout_boxInner + .p-humanAbout_boxInner {
  margin: 4% 0 0;
}

@media screen and (max-width: 767px) {
  .p-humanAbout_boxInner + .p-humanAbout_boxInner {
    margin: 2rem 0 0;
  }
}

.p-humanAbout_pic {
  width: 293px;
  margin: 0 0 0 5%;
}

@media screen and (max-width: 767px) {
  .p-humanAbout_pic {
    width: 100%;
    margin: 3rem 0 0;
  }
}

.p-humanFlow {
  width: 69%;
  margin: 10% auto;
}

@media screen and (min-width: 768px) {
  .p-humanFlow {
    max-width: 683px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanFlow {
    width: 100%;
    margin: 4rem auto;
  }
}

.p-humanFlow_heading {
  margin: 0 0 4%;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-humanFlow_heading {
    margin: 0 0 2rem;
    font-size: 1.6rem;
  }
}

.p-humanFlow_cont {
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e1f3fa;
}

@media screen and (max-width: 767px) {
  .p-humanFlow_cont {
    padding: 1.5rem;
  }
}

.p-humanFlow_cont + .p-humanFlow_cont {
  margin: 40px 0 0;
  position: relative;
  z-index: 1;
}

.p-humanFlow_cont + .p-humanFlow_cont:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 17.5px 0 17.5px;
  border-color: #37ade0 transparent transparent transparent;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
}

.p-humanFlow_pic {
  width: 110px;
  margin: 0 3% 0 0;
}

@media screen and (max-width: 767px) {
  .p-humanFlow_pic {
    margin: 0 1rem 0 0;
  }
}

.p-humanFlow_box {
  flex: 1;
}

.p-humanFlow_lead {
  padding: 4% 0 0;
  font-size: 16px;
  line-height: 2;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-humanFlow_lead {
    font-size: 1.3rem;
  }
}

.p-humanList {
  width: 65%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .p-humanList {
    max-width: 646px;
  }
}

@media screen and (max-width: 767px) {
  .p-humanList {
    width: 100%;
  }
}

.p-humanList_cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-humanList_cont {
    display: block;
  }
}

.p-humanList_contItem {
  width: 293px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .p-humanList_contItem:nth-child(n + 3) {
    margin: 5% 0 0;
  }
}

@media screen and (max-width: 767px) {
  .p-humanList_contItem {
    width: 100%;
  }
  .p-humanList_contItem + .p-humanList_contItem {
    margin: 3rem 0 0;
  }
}

.p-humanList_box {
  width: 100%;
}

.p-humanList_pic {
  width: 100%;
  margin: 8% 0 0;
}

@media screen and (max-width: 767px) {
  .p-humanList_pic {
    margin: 2rem 0 0;
  }
}

.p-humanLeader {
  width: 100%;
  margin: 10% auto;
}

@media screen and (max-width: 767px) {
  .p-humanLeader {
    margin: 4rem auto;
  }
}

.p-humanLeader_cont {
  width: 646px;
  margin: 64px auto 0;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-humanLeader_cont {
    width: 100%;
    margin: 3rem auto 0;
  }
}

.p-humanLeader_list {
  width: 293px;
}

@media screen and (max-width: 767px) {
  .p-humanLeader_list {
    width: 48%;
  }
}

.p-humanStep {
  width: 100%;
  margin: 0 0 15%;
}

@media screen and (max-width: 767px) {
  .p-humanStep {
    margin: 0 0 8rem;
  }
}

.p-humanStep_cont {
  width: 727px;
  margin: 4% auto 0;
  padding: 4%;
  background-color: #e1f3fa;
}

@media screen and (max-width: 767px) {
  .p-humanStep_cont {
    width: 100%;
    margin: 2rem auto 0;
    padding: 2rem 2.5rem;
  }
}

.p-humanStep_inner {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.p-humanStep_inner + .p-humanStep_inner {
  margin: 8px 0 0;
}

.p-humanStep_pic {
  width: 128px;
  margin: 0 20px 0 0;
}

@media screen and (max-width: 767px) {
  .p-humanStep_pic {
    width: 40%;
    margin: 0 1.5rem 0 0;
  }
}

.p-humanStep_arrow {
  position: relative;
  z-index: 1;
}

.p-humanStep_arrow:before {
  content: '';
  width: 22px;
  height: 76px;
  background: url("../img/human/human_step_arrow.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 100%;
  z-index: 2;
}

.p-humanStep_box {
  flex: 1;
}

@media all and (-ms-high-contrast: none) {
  .p-humanList_contItem {
    display: block;
  }
}

.p-indexSkill {
  width: 100%;
  position: relative;
}

.p-indexSkill + .p-indexSkill {
  margin: 80px 0 0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-indexSkill + .p-indexSkill {
    margin: 100px 0 0;
  }
}

@media screen and (min-width: 768px) {
  .p-indexSkill {
    padding: 0 20px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .p-indexSkill {
    padding: 0;
  }
}

.p-indexSkill_pic {
  width: 51%;
  min-width: 520px;
}

@media screen and (max-width: 1024px) {
  .p-indexSkill_pic {
    width: 100%;
    min-width: auto;
  }
}

.p-indexSkill_box {
  min-width: 585px;
  margin: 0 0 0 -20px;
  padding: 2.6% 2%;
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-indexSkill_box {
    margin: -42px auto 0;
    padding: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .p-indexSkill_box {
    width: 90%;
    min-width: auto;
    margin: -21px auto 0;
    position: relative;
    top: auto;
    left: auto;
    z-index: 2;
    transform: none;
  }
}

@media screen and (max-width: 767px) {
  .p-indexSkill_box {
    padding: 24px 20px;
  }
}

.p-indexSkill_heading {
  padding: 0 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-indexSkill_heading {
    width: 100%;
    display: block;
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .p-indexSkill_heading {
    padding: 0 0 16px;
    display: flex;
    justify-content: center;
  }
}

.p-indexSkill_headingInner {
  font-size: 28px;
  font-weight: bold;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .p-indexSkill_headingInner {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 1024px) {
  .p-indexSkill_headingLink.pc {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-indexSkill_headingLink.sp {
    margin: 48px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1024px) {
  .p-indexSkill_headingLink.sp {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .p-indexSkill_headingLink.sp {
    margin: 20px 0 0;
  }
}

.p-indexSkill_text {
  font-size: 14px;
  line-height: 1.8;
}

@media screen and (max-width: 1024px) {
  .p-indexSkill_text {
    font-size: 1.2rem;
    text-align: center;
  }
}

.p-indexSkill_cont {
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .p-indexSkill_cont:after {
    content: '';
    display: block;
    width: 31%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-indexSkill_cont {
    padding: 32px 0 0;
  }
}

.p-indexSkill_list {
  width: 31%;
}

@media screen and (min-width: 768px) {
  .p-indexSkill_list:nth-child(n + 4) {
    margin: 12px 0 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-indexSkill_list {
    width: 48%;
  }
  .p-indexSkill_list:nth-child(n + 3) {
    margin: 24px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .p-indexSkill_list {
    width: 47%;
  }
  .p-indexSkill_list:nth-child(n + 3) {
    margin: 20px 0 0;
  }
}

.p-indexSkill_link {
  width: 100%;
  padding: 12px 0;
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  color: #fff;
  background-color: #37ade0;
  border-radius: 50px;
  border: 2px solid #37ade0;
}

@media screen and (min-width: 768px) {
  .p-indexSkill_link {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .p-indexSkill_link:hover {
    color: #37ade0;
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .p-indexSkill_link {
    padding: 8px 0;
    font-size: 1rem;
  }
}

.p-indexKv {
  position: relative;
  z-index: 1;
}

.p-indexKv_heading {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .p-indexKv_heading {
    width: 95%;
  }
  .p-indexKv_heading img {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .p-indexKv_hero {
    width: 100%;
    height: 50vh;
    background: url("../img/index/index_hero.jpg") no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 767px) {
  .p-indexKv img {
    display: none;
  }
}

.p-indexKv_pagination {
  bottom: 40px !important;
  left: 79% !important;
  transform: translate3d(-50%, 0, 0) !important;
}

@media screen and (max-width: 767px) {
  .p-indexKv_pagination {
    bottom: 20px !important;
    left: 50% !important;
  }
}

.p-indexKv_pagination span {
  width: 16px !important;
  height: 16px !important;
  margin: 0 8px !important;
  opacity: 1 !important;
  background-color: #919191 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
}

@media screen and (max-width: 767px) {
  .p-indexKv_pagination span {
    width: 12px !important;
    height: 12px !important;
    margin: 0 12px !important;
  }
}

.p-indexKv_pagination span.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.p-indexSupport {
  margin: 80px auto 164px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .p-indexSupport {
    width: 100%;
    max-width: 1040px;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-indexSupport {
    margin: 100px auto 120px;
  }
}

@media screen and (max-width: 1024px) {
  .p-indexSupport {
    margin: 40px auto 80px;
    display: block;
  }
}

.p-indexSupport_cont {
  width: 47%;
}

@media screen and (min-width: 768px) {
  .p-indexSupport_cont {
    max-width: 470px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-indexSupport_cont + .p-indexSupport_cont {
    margin: 100px 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .p-indexSupport_cont {
    width: 100%;
    max-width: none;
  }
}

@media screen and (max-width: 767px) {
  .p-indexSupport_cont + .p-indexSupport_cont {
    margin: 80px 0 0;
  }
}

.p-indexSupport_pic {
  position: relative;
  z-index: 1;
}

.p-indexSupport_box {
  width: 80%;
  height: 216px;
  margin: -60px auto 0;
  padding: 32px;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 1024px) {
  .p-indexSupport_box {
    width: 90%;
    height: auto;
    min-width: auto;
    margin: -21px auto 0;
    padding: 24px 20px;
  }
}

.p-indexSupport_heading {
  padding: 0 0 16px;
  font-size: 28px;
  font-weight: bold;
  color: #37ade0;
}

@media screen and (max-width: 1024px) {
  .p-indexSupport_heading {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .p-indexSupport_heading {
    font-size: 20px;
  }
}

.p-indexSupport_text {
  font-size: 14px;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .p-indexSupport_text {
    text-align: left;
  }
}

.p-indexSupport_link {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 32px;
  right: 32px;
}

@media screen and (max-width: 1024px) {
  .p-indexSupport_link {
    margin: 24px 0 0;
    justify-content: center;
    position: static;
  }
}

.p-indexMeister {
  margin: 0 0 160px;
  padding: 68px 0 80px;
  background-color: #e1f3fa;
}

@media screen and (max-width: 767px) {
  .p-indexMeister {
    margin: 0 0 80px;
    padding: 3rem 0 4rem;
  }
}

.p-indexMeister_label {
  margin: 0 0 36px;
}

@media screen and (max-width: 767px) {
  .p-indexMeister_label {
    margin: 0 0 1.2rem;
  }
}

.p-indexMeister_heading {
  padding: 0 0 36px;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-align: center;
  color: #323232;
}

@media screen and (max-width: 767px) {
  .p-indexMeister_heading {
    padding: 0 0 1.2rem;
    font-size: 1.6rem;
  }
}

.p-indexMeister_cont {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-indexMeister_cont {
    flex-flow: column;
  }
}

.p-indexMeister_box {
  width: 100%;
}

.p-indexMeister_link {
  margin: 36px 0 0;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-indexMeister_link {
    justify-content: center;
  }
}

.p-indexMeister_pic {
  width: 44%;
  margin: 36px auto 0;
}

@media screen and (min-width: 768px) {
  .p-indexMeister_pic {
    max-width: 433px;
  }
}

@media screen and (max-width: 767px) {
  .p-indexMeister_pic {
    width: 100%;
    margin: 1.8rem auto 0;
    order: 0;
  }
}

.p-indexNews {
  width: 100%;
  margin: 0 auto 180px;
  padding: 56px 52px;
  background-color: #f0f0f0;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .p-indexNews {
    max-width: 840px;
  }
}

@media screen and (max-width: 767px) {
  .p-indexNews {
    margin: 0 0 80px;
    padding: 36px 12px;
  }
}

.p-indexNews_intro {
  width: 100%;
  padding: 0 0 0 56px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .p-indexNews_intro {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

@media screen and (max-width: 767px) {
  .p-indexNews_intro {
    padding: 0 0 28px;
    align-items: center;
  }
}

.p-indexNews_heading {
  margin: -22px 0 0;
  display: inline-block;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.12em;
  color: #323232;
}

@media screen and (max-width: 767px) {
  .p-indexNews_heading {
    margin: 0;
    font-size: 1.6rem;
  }
}

.p-indexNews_link {
  padding: 8px 20px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #fff;
  background-color: #919191;
  border: 2px solid #919191;
}

@media screen and (min-width: 768px) {
  .p-indexNews_link {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .p-indexNews_link:hover {
    color: #919191;
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .p-indexNews_link {
    padding: 8px 12px;
    font-size: 1.2rem;
  }
}

.p-indexNews_list + .p-indexNews_list {
  margin: 20px 0 0;
}

.p-indexNews_item {
  display: block;
  font-size: 14px;
  line-height: 1.8;
  color: #323232;
}

@media screen and (min-width: 768px) {
  .p-indexNews_item {
    transition: opacity 0.3s ease;
  }
  .p-indexNews_item:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 767px) {
  .p-indexNews_item {
    font-size: 12px;
    line-height: 1.6;
  }
}

.p-indexNews_cont {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  .p-indexNews_cont {
    display: block;
  }
}

.p-indexNews_head {
  margin: 0 12px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-indexNews_head {
    padding: 0 0 12px;
    justify-content: flex-start;
  }
}

.p-indexNews_body {
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

@media screen and (min-width: 768px) {
  .p-indexNews_body {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-indexNews_body a {
    font-size: 1.2rem;
  }
}

.p-indexNews_text {
  font-size: 14px;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .p-indexNews_text {
    font-size: 1.2rem;
    line-height: 1.6;
  }
}

.p-indexNews_label {
  margin: 2px 0 0;
}

@media screen and (max-width: 767px) {
  .p-indexNews_label a {
    font-size: 1.2rem;
  }
}

.p-indexNews_label .post-categories {
  margin: 0 0 8px;
}

.post-categories {
  display: flex;
}

@media screen and (max-width: 767px) {
  .post-categories {
    margin: 0 0 8px;
  }
}

.post-categories li + li {
  margin: 0 0 0 12px;
}

.p-ivIntro {
  width: 100%;
  margin: 132px auto 100px;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .p-ivIntro {
    max-width: 1366px;
  }
}

@media screen and (max-width: 767px) {
  .p-ivIntro {
    margin: 64px 0 56px;
  }
}

.p-ivIntro_cont {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

@media screen and (min-width: 768px) {
  .p-ivIntro_cont {
    max-width: 1000px;
  }
}

@media screen and (max-width: 767px) {
  .p-ivIntro_cont {
    display: block;
  }
}

.p-ivIntro_head {
  width: 100%;
  padding: 40px 40px 32px;
  background-color: #37ade0;
}

@media screen and (min-width: 768px) {
  .p-ivIntro_head {
    max-width: 317px;
    position: absolute;
    top: -36px;
    left: 0;
    z-index: 2;
  }
}

@media screen and (max-width: 767px) {
  .p-ivIntro_head {
    padding: 20px 20px 16px;
  }
}

.p-ivIntro_lead {
  padding: 0 0 136px;
  font-size: 33px;
  font-weight: bold;
  letter-spacing: 0.12em;
  color: #fff;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-ivIntro_lead {
    padding: 0 0 80px;
  }
}

@media screen and (max-width: 767px) {
  .p-ivIntro_lead {
    padding: 0 0 20px;
    font-size: 20px;
  }
}

.p-ivIntro_name {
  font-size: 26px;
  line-height: 1.6;
  letter-spacing: 0.05em;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .p-ivIntro_name {
    font-size: 18px;
  }
}

.p-ivIntro_nameInner {
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.8;
}

.p-ivIntro_heading {
  padding: 0 0 0 18.8%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 768px) {
  .p-ivIntro_heading {
    position: absolute;
    bottom: 20px;
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .p-ivIntro_heading {
    padding: 16px 20px;
    display: block;
    position: relative;
  }
}

.p-ivIntro_heading:after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #37ade0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .p-ivIntro_heading:after {
    display: none;
  }
}

.p-ivIntro_headingInner {
  display: block;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.05em;
  color: #37ade0;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-ivIntro_headingInner {
    font-size: 32px;
  }
}

@media screen and (max-width: 767px) {
  .p-ivIntro_headingInner {
    font-size: 18px;
  }
}

.p-ivIntro_pic {
  width: 71%;
}

@media screen and (min-width: 768px) {
  .p-ivIntro_pic {
    max-width: 710px;
  }
}

@media screen and (max-width: 767px) {
  .p-ivIntro_pic {
    width: 100%;
  }
}

.p-ivInfo {
  width: 100%;
  margin: 0 0 200px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .p-ivInfo {
    margin: 0 0 80px;
    display: block;
  }
}

.p-ivInfo_wrap {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .p-ivInfo_wrap {
    max-width: 896px;
  }
}

@media screen and (max-width: 767px) {
  .p-ivInfo_wrap {
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .p-ivInfo_cont + .p-ivInfo_cont {
    margin: 56px 0 0;
  }
}

.p-ivInfo_cont:first-child {
  width: 40%;
}

@media screen and (min-width: 768px) {
  .p-ivInfo_cont:first-child {
    max-width: 368px;
  }
}

@media screen and (max-width: 767px) {
  .p-ivInfo_cont:first-child {
    width: 100%;
  }
}

.p-ivInfo_cont:last-child {
  width: 55%;
  margin: 0 0 0 36px;
}

@media screen and (min-width: 768px) {
  .p-ivInfo_cont:last-child {
    max-width: 494px;
  }
}

@media screen and (max-width: 767px) {
  .p-ivInfo_cont:last-child {
    width: 100%;
  }
}

.p-ivInfo_box + .p-ivInfo_box {
  margin: 88px 0 0;
}

@media screen and (max-width: 767px) {
  .p-ivInfo_box + .p-ivInfo_box {
    margin: 56px 0 0;
  }
}

.p-ivInfo_lead {
  padding: 0 0 24px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.05em;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .p-ivInfo_lead {
    padding: 0 0 8px;
    font-size: 16px;
  }
}

.p-ivInfo_text {
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0.05em;
}

.p-ivInfo_pic {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-ivInfo_pic {
    margin: 5% 0 0;
  }
}

.p-ivInfo_pic + .p-ivInfo_pic {
  margin: 68px 0 0;
}

@media screen and (max-width: 767px) {
  .p-ivInfo_pic + .p-ivInfo_pic {
    margin: 56px 0 0;
  }
}

.p-ivInfo_pic--profile {
  padding: 16px;
  background-color: #37ade0;
}

.p-ivInfo_picText {
  padding: 20px 8px 16px;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.05em;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .p-ivInfo_picText {
    padding: 12px;
  }
}

.p-medical {
  width: 100%;
  margin: 0 0 15%;
}

@media screen and (max-width: 767px) {
  .p-medical {
    margin: 0 0 8rem;
  }
}

.p-medical_inner + .p-medical_inner {
  margin: 12% 0 0;
}

@media screen and (max-width: 767px) {
  .p-medical_inner + .p-medical_inner {
    margin: 6rem 0 0;
  }
}

.p-medical_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-medical_cont {
    flex-flow: column;
  }
}

@media screen and (min-width: 768px) {
  .p-medical_box {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-medical_box {
    width: 100%;
    order: 1;
  }
}

.p-medical_pic, .p-medical_pic--right, .p-medical_pic--left {
  width: 50%;
}

@media screen and (min-width: 768px) {
  .p-medical_pic, .p-medical_pic--right, .p-medical_pic--left {
    max-width: 494px;
  }
}

@media screen and (max-width: 767px) {
  .p-medical_pic, .p-medical_pic--right, .p-medical_pic--left {
    width: 100%;
    margin: 0 0 2rem;
    order: 0;
  }
}

@media screen and (min-width: 768px) {
  .p-medical_pic--right {
    margin: 0 0 0 3%;
  }
}

@media screen and (min-width: 768px) {
  .p-medical_pic--left {
    margin: 0 3% 0 0;
  }
}

.p-medical_img {
  width: 633px;
  margin: 5% auto 0;
}

@media screen and (max-width: 767px) {
  .p-medical_img {
    width: 80%;
    margin: 3rem auto 0;
  }
}

.p-medicalInfo {
  width: 100%;
  margin: 24px 0 0;
  padding: 16px;
  background-color: #e1f3fa;
}

@media screen and (max-width: 767px) {
  .p-medicalInfo {
    margin: 2rem 0 0;
    padding: 1.5rem;
  }
}

.p-medicalInfo_lead {
  padding: 0 0 8px;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .p-medicalInfo_lead {
    font-size: 1.3rem;
  }
}

.p-medicalInfo_list {
  display: flex;
}

.p-medicalInfo_ico {
  width: 14px;
  height: 14px;
  margin: 6px 8px 0 0;
  display: block;
  background-color: #37ade0;
}

.p-news_date {
  margin: 0 32px 0 0;
  display: block;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .p-news_date {
    margin: 0 16px 0 0;
    font-size: 13px;
  }
}

.p-news_label a,
.p-news_label span {
  padding: 4px 8px;
  display: block;
  font-size: 13px;
  border: 1px solid;
}

@media screen and (min-width: 768px) {
  .p-news_label a,
  .p-news_label span {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
}

@media screen and (max-width: 767px) {
  .p-news_label a,
  .p-news_label span {
    font-size: 12px;
  }
}

.p-news_label--blue a,
.p-news_label--blue span {
  color: #fff;
  background-color: #37ade0;
  border-color: #37ade0;
}

@media screen and (min-width: 768px) {
  .p-news_label--blue a:hover,
  .p-news_label--blue span:hover {
    color: #37ade0;
    background-color: #fff;
  }
}

.p-news_list {
  border-bottom: 1px solid #323232;
}

.p-news_list + .p-news_list {
  margin: 40px 0 0;
}

@media screen and (max-width: 767px) {
  .p-news_list + .p-news_list {
    margin: 20px 0 0;
  }
}

.p-news_link {
  width: 100%;
  display: block;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
}

@media screen and (min-width: 768px) {
  .p-news_link {
    transition: color 0.3s ease;
  }
  .p-news_link:hover {
    color: #37ade0;
  }
}

.p-newsBox_head {
  display: flex;
  align-items: center;
}

.p-newsBox_body {
  padding: 32px 0;
}

@media screen and (max-width: 767px) {
  .p-newsBox_body {
    padding: 16px 0;
  }
}

.p-newsDetail_heading {
  margin: 0 0 28px;
  padding: 0 0 24px;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  border-bottom: 1px solid #919191;
}

@media screen and (max-width: 767px) {
  .p-newsDetail_heading {
    margin: 0 0 16px;
    padding: 0 0 12px;
    font-size: 20px;
    line-height: 1.3;
  }
}

.p-newsDetail_intro {
  width: 100%;
  margin: 0 0 60px;
  display: flex;
  justify-content: space-between;
}

.p-newsDetail_cont p {
  margin: 0 0 48px;
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 767px) {
  .p-newsDetail_cont p {
    margin: 0 0 24px;
  }
}

.p-newsDetail_btn {
  width: 250px;
  margin: 80px auto 0;
}

@media screen and (max-width: 767px) {
  .p-newsDetail_btn {
    width: 80%;
    margin: 40px auto 0;
  }
}

.p-nurseBox {
  width: 100%;
  margin: 0 auto 160px;
  padding: 48px;
  border: 2px solid #37ade0;
}

@media screen and (min-width: 768px) {
  .p-nurseBox {
    max-width: 834px;
  }
}

@media screen and (max-width: 767px) {
  .p-nurseBox {
    width: 90%;
    margin: 0 auto 80px;
    padding: 24px;
  }
}

.p-nurseBox_cont {
  display: flex;
  justify-content: space-between;
}

.p-nurseBox_cont + .p-nurseBox_cont {
  margin: 24px 0 0;
}

.p-nurseBox_head {
  width: 64px;
  margin: 0 24px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .p-nurseBox_head {
    width: 60%;
    margin: 0 16px 0 0;
    align-items: flex-start;
  }
}

@media screen and (min-width: 768px) {
  .p-nurseBox_body {
    flex: 1;
  }
}

.p-nurseBox_lead {
  padding: 0 0 12px;
  font-size: 20px;
  font-weight: 500;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .p-nurseBox_lead {
    font-size: 16px;
  }
}

.p-nurseBox_text {
  font-size: 14px;
  line-height: 1.7;
}

.p-nurseFlow {
  margin: 0 0 356px;
  padding: 100px 0 80px;
  background-color: #e1f3fa;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-nurseFlow {
    margin: 0 0 120px;
  }
}

@media screen and (max-width: 767px) {
  .p-nurseFlow {
    margin: 0 0 64px;
    padding: 56px 24px 40px;
  }
}

.p-nurseFlow_circle {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #37ade0;
  position: absolute;
  top: -60px;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .p-nurseFlow_circle {
    width: 60px;
    height: 60px;
    top: -30px;
  }
}

.p-nurseFlow_circle img {
  width: 74px;
}

@media screen and (max-width: 767px) {
  .p-nurseFlow_circle img {
    width: 37px;
  }
}

.p-nurseFlow_heading {
  padding: 0 0 16px;
  font-size: 35px;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  color: #37ade0;
}

@media screen {
  .p-nurseFlow_heading {
    font-size: 20px;
  }
}

.p-nurseFlow_headingInner {
  display: block;
  font-size: 25px;
}

@media screen and (max-width: 767px) {
  .p-nurseFlow_headingInner {
    font-size: 16px;
  }
}

.p-nurseFlow_text {
  font-size: 16px;
  line-height: 2;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-nurseFlow_text {
    font-size: 14px;
    text-align: left;
  }
}

.p-nurseFlow_box {
  width: 100%;
  margin: 44px auto 0;
}

@media screen and (min-width: 768px) {
  .p-nurseFlow_box {
    max-width: 430px;
  }
}

@media screen and (max-width: 767px) {
  .p-nurseFlow_box {
    width: 90%;
    margin: 24px auto 0;
  }
}

.p-nurseFlow_cont {
  display: flex;
  position: relative;
}

.p-nurseFlow_cont:after {
  content: '';
  width: 100%;
  height: 1px;
  border-bottom: 2px dotted #fff;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 2;
}

.p-nurseFlow_cont:last-child:after {
  display: none;
}

.p-nurseFlow_head {
  width: 142px;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffb3b3;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .p-nurseFlow_head {
    width: 40%;
  }
}

.p-nurseFlow_head--deep {
  background-color: #ffa0a0;
}

.p-nurseFlow_head img {
  width: auto;
  height: 32px;
  display: block;
}

@media screen and (max-width: 767px) {
  .p-nurseFlow_head img {
    height: 24px;
  }
}

.p-nurseFlow_arrow {
  width: 18px;
  height: calc(100% - 7px);
  background: linear-gradient(180deg, #ffd497 0%, #ffaa30 99.71%);
  position: absolute;
  top: 0;
  right: calc(100% + 10px);
  z-index: 2;
}

.p-nurseFlow_arrow:after {
  content: '';
  margin: -1px 0 0;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 9px 0 9px;
  border-color: #ffaa30 transparent transparent transparent;
  position: absolute;
  top: 100%;
  left: 0;
}

.p-nurseFlow_body {
  padding: 16px;
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
  line-height: 1.6;
  background-color: #f9f9f9;
}

.p-nurseFlow_body--deep {
  background-color: #f0f0f0;
}

.p-nurseOther {
  width: 100%;
  margin: 0 auto 156px;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  .p-nurseOther {
    max-width: 1000px;
  }
}

@media screen and (max-width: 1024px) {
  .p-nurseOther {
    width: 90%;
    margin: 0 auto 80px;
    display: block;
  }
}

.p-nurseOther_box {
  width: 46%;
  padding: 86px 40px 40px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background-color: #e1f3fa;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .p-nurseOther_box {
    max-width: 460px;
  }
}

@media screen and (max-width: 1024px) {
  .p-nurseOther_box {
    width: 100%;
    padding: 32px 16px;
    max-width: none;
  }
  .p-nurseOther_box + .p-nurseOther_box {
    margin: 80px 0 0;
  }
}

.p-nurseOther_pic {
  width: calc(100% - 80px);
  position: absolute;
  bottom: 90%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 1024px) {
  .p-nurseOther_pic {
    width: 100%;
    margin: 0 0 32px;
    position: static;
    transform: none;
  }
}

.p-nurseOther_intro {
  margin: 0 0 28px;
}

.p-nurseOther_lead {
  padding: 0 0 16px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #37ade0;
}

.p-nurseOther_text {
  font-size: 14px;
  line-height: 2;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_text {
    text-align: left;
  }
}

.p-nurseOther_wrap {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.p-nurseOther_cont {
  padding: 8px;
  display: flex;
  align-items: center;
}

.p-nurseOther_cont--green01 {
  width: 85%;
  background-color: #008c64;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_cont--green01 {
    width: 100%;
  }
}

.p-nurseOther_cont--green02 {
  width: 90%;
  background-color: #00a678;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_cont--green02 {
    width: 100%;
  }
}

.p-nurseOther_cont--green03 {
  width: 95%;
  background-color: #00be96;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_cont--green03 {
    width: 100%;
  }
}

.p-nurseOther_cont--green04 {
  width: 100%;
  background-color: #4cd1b5;
}

.p-nurseOther_contHead {
  width: 52px;
  height: 52px;
  margin: 0 8px 0 0;
  padding: 0 0 4px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: #fff;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_contHead {
    width: 48px;
    height: 48px;
  }
}

.p-nurseOther_contHead--green01 {
  color: #008c64;
}

.p-nurseOther_contHead--green02 {
  color: #00a678;
}

.p-nurseOther_contHead--green03 {
  color: #00be96;
}

.p-nurseOther_contHead--green04 {
  color: #4cd1b5;
}

.p-nurseOther_contNum {
  font-size: 26px;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_contNum {
    font-size: 20px;
  }
}

.p-nurseOther_contBody {
  flex: 1;
}

.p-nurseOther_contLead {
  padding: 0 0 8px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.p-nurseOther_contText {
  font-size: 13px;
  color: #fff;
}

.p-nurseOther_info {
  width: 90%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_info {
    width: 100%;
  }
}

.p-nurseOther_info + .p-nurseOther_info {
  margin: 26px auto 0;
}

.p-nurseOther_infoHead {
  padding: 16px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background-color: #37ade0;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_infoHead {
    font-size: 18px;
  }
}

.p-nurseOther_infoBody {
  padding: 20px 16px;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
}

.p-nurseOther_infoBody--center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-nurseOther_infoBody--center {
    text-align: left;
  }
}

.p-qc_cont {
  width: 100%;
  margin: 52px auto 0;
}

@media screen and (min-width: 768px) {
  .p-qc_cont {
    max-width: 840px;
  }
}

@media screen and (max-width: 767px) {
  .p-qc_cont {
    margin: 36px auto 0;
  }
}

.p-qcFlow {
  margin: 52px 0 100px;
  padding: 78px 58px 20px;
  background-color: #e1f3fa;
}

@media screen and (max-width: 767px) {
  .p-qcFlow {
    margin: 40px 0 64px;
    padding: 56px 24px 24px;
  }
}

.p-qcFlow_cont {
  padding: 0 0 40px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-qcFlow_cont {
    display: block;
  }
}

.p-qcFlow_list {
  width: 25%;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .p-qcFlow_list {
    max-width: 220px;
  }
}

@media screen and (max-width: 767px) {
  .p-qcFlow_list {
    width: 100%;
  }
}

.p-qcFlow_list--arrow {
  width: 9%;
}

@media screen and (min-width: 768px) {
  .p-qcFlow_list--arrow {
    max-width: 72px;
  }
}

@media screen and (max-width: 767px) {
  .p-qcFlow_list--arrow {
    width: 72px;
    margin: 40px auto;
    transform: rotate(90deg);
  }
}

.p-qcFlow_note {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .p-qcFlow_note {
    font-size: 13px;
  }
}

.p-qcList {
  width: 100%;
  margin: 64px auto 0;
  padding: 48px 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: #e1f3fa;
}

@media screen and (min-width: 768px) {
  .p-qcList {
    max-width: 627px;
  }
}

@media screen and (max-width: 767px) {
  .p-qcList {
    margin: 12% auto 0;
    padding: 10% 6%;
  }
}

.p-qcList_cont {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-qcList_cont {
    display: block;
  }
}

.p-qcList_item {
  width: 49%;
  display: flex;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .p-qcList_item:nth-child(n + 3) {
    margin: 32px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .p-qcList_item {
    width: 100%;
    font-size: 13px;
  }
  .p-qcList_item + .p-qcList_item {
    margin: 5% 0 0;
  }
}

.p-qcList_ico {
  color: #37ade0;
}

.p-rehaPower {
  margin: 0 0 10%;
}

@media screen and (max-width: 767px) {
  .p-rehaPower {
    margin: 0 0 5rem;
  }
}

.p-rehaPower_main {
  width: 566px;
  margin: 0 auto 6%;
}

@media screen and (max-width: 767px) {
  .p-rehaPower_main {
    width: 90%;
    margin: 0 auto 3rem;
  }
}

.p-rehaPower_cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-rehaPower_cont {
    display: block;
  }
}

.p-rehaPower_list {
  width: 28%;
}

@media screen and (min-width: 768px) {
  .p-rehaPower_list {
    max-width: 280px;
  }
}

@media screen and (max-width: 767px) {
  .p-rehaPower_list {
    width: 100%;
  }
  .p-rehaPower_list + .p-rehaPower_list {
    margin: 3rem 0 0;
  }
}

.p-rehaPower_label, .p-rehaPower_label--yellow, .p-rehaPower_label--blue, .p-rehaPower_label--pink {
  margin: 0 0 16px;
  padding: 8px 0;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .p-rehaPower_label, .p-rehaPower_label--yellow, .p-rehaPower_label--blue, .p-rehaPower_label--pink {
    margin: 0 0 1.5rem;
    padding: 1rem 0;
    font-size: 1.4rem;
  }
}

.p-rehaPower_label--yellow {
  background-color: #ffd232;
}

.p-rehaPower_label--blue {
  background-color: #6e8cff;
}

.p-rehaPower_label--pink {
  background-color: #ffa0a0;
}

.p-rehaPower_pic {
  margin: 0 0 16px;
}

@media screen and (max-width: 767px) {
  .p-rehaPower_pic {
    margin: 0 0 1.5rem;
  }
}

.p-rehaCard {
  margin: 5% 0 10%;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-rehaCard {
    margin: 0 0 6rem;
    display: block;
  }
}

.p-rehaCard_list {
  width: 28%;
}

@media screen and (min-width: 768px) {
  .p-rehaCard_list {
    max-width: 280px;
  }
}

@media screen and (max-width: 767px) {
  .p-rehaCard_list {
    width: 100%;
  }
  .p-rehaCard_list + .p-rehaCard_list {
    margin: 3rem 0 0;
  }
}

.p-rehaCard_heading {
  margin: 0 0 28px;
  padding: 0 0 16px;
  font-size: 20px;
  font-weight: 500;
  color: #37ade0;
  border-bottom: 2px solid #37ade0;
}

@media screen and (max-width: 767px) {
  .p-rehaCard_heading {
    margin: 0 0 16px;
    padding: 0 0 8px;
    font-size: 18px;
  }
}

.p-rehaCard_pic {
  margin: 0 0 12px;
}

.p-rehaCard_text {
  font-size: 14px;
  line-height: 1.7;
}

.p-rpMedia {
  width: 100%;
  margin: 0 auto 160px;
  padding: 120px 0 0;
}

@media screen and (min-width: 768px) {
  .p-rpMedia {
    max-width: 1366px;
  }
}

@media screen and (max-width: 767px) {
  .p-rpMedia {
    width: 90%;
    margin: 0 auto 80px;
    padding: 0;
  }
}

.p-rpMedia_cont {
  width: 78%;
  display: flex;
  align-items: flex-end;
}

@media screen and (min-width: 768px) {
  .p-rpMedia_cont {
    max-width: 1057px;
  }
}

@media screen and (max-width: 767px) {
  .p-rpMedia_cont {
    width: 100%;
    display: block;
  }
}

.p-rpMedia_pic {
  width: 59%;
  margin: 0 32px 0 0;
}

@media screen and (min-width: 768px) {
  .p-rpMedia_pic {
    max-width: 616px;
  }
}

@media screen and (max-width: 767px) {
  .p-rpMedia_pic {
    width: 100%;
    margin: 0 0 32px;
  }
}

@media screen and (min-width: 768px) {
  .p-rpMedia_box {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-rpMedia_box {
    width: 100%;
  }
}

.p-rpMedia_label {
  width: 100%;
}

.p-rpMedia_heading {
  padding: 24px 0 8px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.6;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .p-rpMedia_heading {
    font-size: 20px;
  }
}

.p-rpMedia_text {
  font-size: 18px;
  line-height: 1.7;
}

@media screen and (max-width: 767px) {
  .p-rpMedia_text {
    font-size: 16px;
  }
}

.p-rpCont {
  margin: 0 0 164px;
  padding: 72px 0 100px;
  background-color: #e1f3fa;
}

@media screen and (max-width: 767px) {
  .p-rpCont {
    margin: 0 0 80px;
    padding: 40px 24px;
  }
}

.p-rpCont_box {
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .p-rpCont_box {
    max-width: 840px;
  }
}

.p-rpCont_heading {
  padding: 0 0 12px;
  font-size: 35px;
  font-weight: 500;
  line-height: 2;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-rpCont_heading {
    font-size: 20px;
    line-height: 1.6;
  }
}

.p-rpCont_lead {
  padding: 0 0 4px;
  font-size: 18px;
  line-height: 1.7;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-rpCont_lead {
    padding: 0 0 12px;
    font-size: 16px;
    text-align: left;
  }
}

.p-rpCont_text {
  font-size: 14px;
  line-height: 1.8;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-rpCont_text {
    padding: 0 0 0 1em;
    text-indent: -1em;
    text-align: left;
  }
}

.p-rpCont_pic {
  margin: 44px 0 0;
}

@media screen and (max-width: 767px) {
  .p-rpCont_pic {
    margin: 24px 0 0;
  }
}

@media all and (-ms-high-contrast: none) {
  .p-rpMedia_heading {
    font-size: 32px;
  }
}

.p-tech {
  margin: 0 0 160px;
}

.p-tech_circle {
  width: 62%;
  margin: 0 auto 128px;
}

@media screen and (min-width: 768px) {
  .p-tech_circle {
    max-width: 840px;
  }
}

@media screen and (max-width: 767px) {
  .p-tech_circle {
    width: 90%;
    margin: 0 auto 64px;
  }
}

.p-tech_wrap {
  width: 100%;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .p-tech_wrap {
    max-width: 650px;
  }
}

@media screen and (max-width: 767px) {
  .p-tech_wrap {
    width: 90%;
  }
}

.p-tech_cont {
  padding: 40px 0 0;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-tech_cont {
    padding: 24px 0 0;
    display: block;
  }
  .p-tech_cont + .p-tech_cont {
    padding: 80px 0 0;
  }
}

.p-tech_pic {
  width: 46%;
}

@media screen and (min-width: 768px) {
  .p-tech_pic {
    max-width: 293px;
  }
}

@media screen and (max-width: 767px) {
  .p-tech_pic {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .p-tech_box {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.p-tech_heading {
  width: 100%;
  padding: 16px 0 16px 15%;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  border-bottom: 2px solid;
}

@media screen and (max-width: 767px) {
  .p-tech_heading {
    padding: 16px 0;
    font-size: 20px;
    text-align: center;
  }
}

.p-tech_heading--pink {
  color: #ffa0a0;
  border-color: #ffa0a0;
}

.p-tech_heading--orange {
  color: #ffaa30;
  border-color: #ffaa30;
}

.p-tech_heading--yellow {
  color: #ffd232;
  border-color: #ffd232;
}

.p-tech_heading--green {
  color: #00be96;
  border-color: #00be96;
}

.p-tech_heading--blue {
  color: #6e8cff;
  border-color: #6e8cff;
}

.p-tech_btn {
  width: 80%;
}

@media screen and (min-width: 768px) {
  .p-tech_btn {
    max-width: 294px;
  }
}

@media screen and (max-width: 767px) {
  .p-tech_btn {
    margin: 32px auto 0;
  }
}

.p-tech_btnInner {
  padding: 16px 0 16px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.025em;
  color: #fff;
  border: 2px solid;
  border-radius: 100px;
}

@media screen and (min-width: 768px) {
  .p-tech_btnInner {
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  .p-tech_btnInner .p-tech_btnArrow {
    transition: background-color 0.3s ease;
  }
  .p-tech_btnInner .p-tech_btnArrow:after {
    transition: border-color 0.3s ease;
  }
  .p-tech_btnInner:hover {
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .p-tech_btnInner {
    padding: 12px 0 12px 24px;
    font-size: 14px;
  }
}

.p-tech_btnInner--pink {
  background-color: #ffa0a0;
  border-color: #ffa0a0;
}

@media screen and (min-width: 768px) {
  .p-tech_btnInner--pink:hover {
    color: #ffa0a0;
  }
  .p-tech_btnInner--pink:hover .p-tech_btnArrow {
    background-color: #ffa0a0;
  }
  .p-tech_btnInner--pink:hover .p-tech_btnArrow:after {
    border-color: #ffa0a0;
  }
}

.p-tech_btnInner--orange {
  background-color: #ffaa30;
  border-color: #ffaa30;
}

@media screen and (min-width: 768px) {
  .p-tech_btnInner--orange:hover {
    color: #ffaa30;
  }
  .p-tech_btnInner--orange:hover .p-tech_btnArrow {
    background-color: #ffaa30;
  }
  .p-tech_btnInner--orange:hover .p-tech_btnArrow:after {
    border-color: #ffaa30;
  }
}

.p-tech_btnInner--yellow {
  background-color: #ffd232;
  border-color: #ffd232;
}

@media screen and (min-width: 768px) {
  .p-tech_btnInner--yellow:hover {
    color: #ffd232;
  }
  .p-tech_btnInner--yellow:hover .p-tech_btnArrow {
    background-color: #ffd232;
  }
  .p-tech_btnInner--yellow:hover .p-tech_btnArrow:after {
    border-color: #ffd232;
  }
}

.p-tech_btnInner--green {
  background-color: #00be96;
  border-color: #00be96;
}

@media screen and (min-width: 768px) {
  .p-tech_btnInner--green:hover {
    color: #00be96;
  }
  .p-tech_btnInner--green:hover .p-tech_btnArrow {
    background-color: #00be96;
  }
  .p-tech_btnInner--green:hover .p-tech_btnArrow:after {
    border-color: #00be96;
  }
}

.p-tech_btnInner--blue {
  background-color: #6e8cff;
  border-color: #6e8cff;
}

@media screen and (min-width: 768px) {
  .p-tech_btnInner--blue:hover {
    color: #6e8cff;
  }
  .p-tech_btnInner--blue:hover .p-tech_btnArrow {
    background-color: #6e8cff;
  }
  .p-tech_btnInner--blue:hover .p-tech_btnArrow:after {
    border-color: #6e8cff;
  }
}

.p-tech_btnArrow {
  width: 100%;
  height: 1px;
  margin: 0 0 0 12px;
  background-color: #fff;
  position: relative;
}

.p-tech_btnArrow:after {
  content: '';
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  position: absolute;
  top: calc(50% + 0.5px);
  right: 0;
  transform: translateY(-50%) rotate(-45deg);
}

@media screen and (min-width: 768px) {
  .p-tech_btnArrow {
    max-width: 70px;
  }
}

@media screen and (max-width: 767px) {
  .p-tech_btnArrow {
    width: 40px;
  }
}

.p-techIntro {
  width: 100%;
  margin: -28px auto 140px;
  display: flex;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .p-techIntro {
    max-width: 840px;
  }
}

@media screen and (max-width: 767px) {
  .p-techIntro {
    width: 90%;
    margin: 32px auto 64px;
    display: block;
  }
}

.p-techIntro_box {
  padding: 24px 56px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .p-techIntro_box {
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-techIntro_box {
    width: 100%;
    padding: 32px;
  }
}

.p-techIntro_box--pink {
  background-color: #ffa0a0;
}

.p-techIntro_box--orange {
  background-color: #ffaa30;
}

.p-techIntro_box--yellow {
  background-color: #ffd232;
}

.p-techIntro_box--green {
  background-color: #00be96;
}

.p-techIntro_box--blue {
  background-color: #6e8cff;
}

.p-techIntro_heading {
  padding: 0 0 24px;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.4;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .p-techIntro_heading {
    padding: 0 0 8px;
    font-size: 20px;
  }
}

.p-techIntro_text {
  font-size: 14px;
  line-height: 2;
  color: #fff;
}

.p-techIntro_pic {
  width: 48%;
}

@media screen and (min-width: 768px) {
  .p-techIntro_pic {
    max-width: 406px;
  }
}

@media screen and (max-width: 767px) {
  .p-techIntro_pic {
    width: 100%;
  }
}

.p-techMedia {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-techMedia {
    display: block;
  }
}

.p-techMedia + .p-techMedia {
  margin: 68px 0 0;
}

@media screen and (max-width: 767px) {
  .p-techMedia + .p-techMedia {
    margin: 40px 0 0;
  }
}

.p-techMedia_pic {
  width: 49%;
}

@media screen and (min-width: 768px) {
  .p-techMedia_pic {
    max-width: 490px;
  }
}

@media screen and (max-width: 767px) {
  .p-techMedia_pic {
    width: 100%;
  }
}

.p-techMedia_pic--left {
  margin: 0 40px 0 0;
}

@media screen and (max-width: 767px) {
  .p-techMedia_pic--left {
    margin: 0 0 32px;
  }
}

.p-techMedia_pic--right {
  margin: 0 0 0 40px;
}

@media screen and (max-width: 767px) {
  .p-techMedia_pic--right {
    margin: 32px 0 0;
  }
}

.p-techMedia_picHead {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .p-techMedia_picHead {
    display: block;
  }
}

.p-techMedia_picHead img {
  width: 82%;
}

@media screen and (min-width: 768px) {
  .p-techMedia_picHead img {
    max-width: 400px;
  }
}

@media screen and (max-width: 767px) {
  .p-techMedia_picHead img {
    width: 100%;
  }
}

.p-techMedia_picBody {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .p-techMedia_picBody {
    display: block;
  }
}

.p-techMedia_cont {
  width: 82%;
  margin: -20px 0 0;
  padding: 4px;
  background-color: #37ade0;
}

@media screen and (max-width: 767px) {
  .p-techMedia_cont {
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .p-techMedia_box {
    flex: 1;
  }
}

.p-techMedia_boxInner + .p-techMedia_boxInner {
  margin: 48px 0 0;
}

@media screen and (max-width: 767px) {
  .p-techMedia_boxInner + .p-techMedia_boxInner {
    margin: 24px 0 0;
  }
}

@media screen and (max-width: 767px) {
  .p-techMedia_box {
    width: 100%;
  }
}

.p-techMedia_lead {
  padding: 0 0 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: #37ade0;
}

@media screen and (max-width: 767px) {
  .p-techMedia_lead {
    padding: 0 0 8px;
    font-size: 16px;
  }
}

.p-techMedia_text {
  font-size: 14px;
  line-height: 1.6;
}
