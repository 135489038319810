.p-iv {
  &Intro {
    width: 100%;
    margin: 132px auto 100px;
    position: relative;
    z-index: 1;

    @include pc {
      max-width: 1366px;
    }

    @include sp {
      margin: 64px 0 56px;
    }

    &_cont {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
      position: relative;

      @include pc {
        max-width: 1000px;
      }

      @include sp {
        display: block;
      }
    }

    &_head {
      width: 100%;
      padding: 40px 40px 32px;
      background-color: $main;

      @include pc {
        max-width: 317px;
        position: absolute;
        top: -36px;
        left: 0;
        z-index: 2;
      }

      @include sp {
        padding: 20px 20px 16px;
      }
    }

    &_lead {
      padding: 0 0 136px;
      font-size: 33px;
      font-weight: bold;
      letter-spacing: 0.12em;
      color: $white;

      @include tab {
        padding: 0 0 80px;
      }

      @include sp {
        padding: 0 0 20px;
        font-size: 20px;
      }
    }

    &_name {
      font-size: 26px;
      line-height: 1.6;
      letter-spacing: 0.05em;
      color: $white;

      @include sp {
        font-size: 18px;
      }

      &Inner {
        display: block;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.8;
      }
    }

    &_heading {
      padding: 0 0 0 18.8%;
      display: flex;
      justify-content: flex-end;

      @include pc {
        position: absolute;
        bottom: 20px;
        left: 0;
      }

      @include sp {
        padding: 16px 20px;
        display: block;
        position: relative;
      }

      &:after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: $main;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @include sp {
          display: none;
        }
      }

      &Inner {
        display: block;
        font-size: 36px;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.05em;
        color: $main;

        @include tab {
          font-size: 32px;
        }

        @include sp {
          font-size: 18px;
        }
      }
    }

    &_pic {
      width: 71%;

      @include pc {
        max-width: 710px;
      }

      @include sp {
        width: 100%;
      }
    }
  }

  &Info {
    width: 100%;
    margin: 0 0 200px;
    display: flex;
    justify-content: flex-end;

    @include sp {
      margin: 0 0 80px;
      display: block;
    }

    &_wrap {
      width: 90%;
      display: flex;
      justify-content: space-between;

      @include pc {
        max-width: 896px;
      }

      @include sp {
        width: 100%;
        display: block;
      }
    }

    &_cont {
      @include sp {
        & + & {
          margin: 56px 0 0;
        }
      }

      &:first-child {
        width: 40%;

        @include pc {
          max-width: 368px;
        }

        @include sp {
          width: 100%;
        }
      }

      &:last-child {
        width: 55%;
        margin: 0 0 0 36px;

        @include pc {
          max-width: 494px;
        }

        @include sp {
          width: 100%;
        }
      }
    }

    &_box {
      & + & {
        margin: 88px 0 0;

        @include sp {
          margin: 56px 0 0;
        }
      }
    }

    &_lead {
      padding: 0 0 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.05em;
      color: $main;

      @include sp {
        padding: 0 0 8px;
        font-size: 16px;
      }
    }

    &_text {
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: 0.05em;
    }

    &_pic {
      width: 100%;

      @include sp {
        margin: 5% 0 0;
      }

      & + & {
        margin: 68px 0 0;

        @include sp {
          margin: 56px 0 0;
        }
      }

      &--profile {
        padding: 16px;
        background-color: $main;
      }

      &Text {
        padding: 20px 8px 16px;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: 0.05em;
        color: $white;

        @include sp {
          padding: 12px;
        }
      }
    }
  }
}
