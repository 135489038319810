.l-header {
  width: 100%;
  height: 150px; // 案件ごとに変更
  padding: 20px 0;
  display: flex;
  background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9990;

  @include tab {
    height: 98px;
    padding: 16px 0;
  }

  @include sp {
    height: 83px;
    padding: 12px 0;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  }

  &_cont {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include sp {
      height: 100%;
    }
  }

  &_logo {
    &Item {
      display: block;
      color: $blackPale;

      @include pc {
        transition: opacity $anime;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &Text {
      padding: 0 0 8px;
      font-size: 14px;
      font-weight: bold;

      @include tab {
        font-size: 10px;
      }

      @include sp {
        font-size: 10px;
      }
    }

    &Img {
      width: 150px;

      @include tab {
        width: 100px;
      }

      @include sp {
        width: 80px;
      }
    }
  }

  &_nav {
    flex: 1;

    @include pc {
      margin: 20px 0 0;
    }

    @include sp {
      width: 100vw;
      height: calc(100vh - 83px);
      padding: 0 0 48px;
      background-color: $white;
      position: fixed;
      top: 83px;
      left: 0;
      z-index: -9998;
      pointer-events: none;
      opacity: 0;
      overflow-y: scroll;
    }

    &.is-view {
      z-index: 9998;
      pointer-events: auto;
      opacity: 1;
      transition: opacity 0.3s;
    }
  }

  &_btn {
    width: 92px;
    position: absolute;
    top: -20px;
    right: 0;

    @include sp {
      width: 90%;
      margin: 32px auto 60px;
      position: static;
    }

    &Item {
      width: 100%;
      height: 100%;
      padding: 8px 0;
      display: block;
      font-size: 14px;
      line-height: 1.6;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-align: center;
      color: $white;
      background-color: $main;
      border: 2px solid $main;
      border-top: none;
      border-radius: 0 0 6px 6px;

      @include pc {
        transition: color $anime, background-color $anime;

        &:hover {
          color: $main;
          background-color: $white;
        }
      }

      @include tab {
        padding: 4px 0;
        top: -16px;
      }

      @include sp {
        padding: 12px 0;
        font-size: 1.3rem;
        border-radius: 0;
      }
    }
  }

  &Hamburger {
    display: none;

    @include sp {
      width: 40px;
      height: 24px;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 9999;
      transform: translateY(-50%);
    }

    &.is-active {
      .l-headerHamburger {
        &_line {
          &--top {
            width: 90%;
            top: 50%;
            transform: translate(-50%, 50%) rotate(-45deg);
          }

          &--mid {
            opacity: 0;
          }

          &--btm {
            width: 90%;
            top: 50%;
            transform: translate(-50%, 50%) rotate(45deg);
          }
        }
      }
    }

    &_line {
      width: 100%;
      height: 6px;
      background-color: $grayDeep;
      position: absolute;
      left: 50%;

      @include sp {
        height: 3px;
      }

      &--top {
        @extend .l-headerHamburger_line;
        top: 0;
        transform: translateX(-50%);
        transition: width 0.3s, transform 0.3s;
      }

      &--mid {
        @extend .l-headerHamburger_line;
        top: 50%;
        transform: translateX(-50%);
        transition: opacity 0.3s;
      }

      &--btm {
        @extend .l-headerHamburger_line;
        top: 100%;
        transform: translateX(-50%);
        transition: width 0.3s, transform 0.3s;
      }
    }
  }

  &Nav {
    width: 100%;

    @include sp {
    }

    &_cont {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include sp {
        display: block;
        border-bottom: 2px solid $white;
      }
    }

    &_list {
      position: relative;

      @include sp {
        width: 100%;
      }

      & + & {
        margin: 0 0 0 40px;

        @include tab {
          margin: 0 0 0 20px;
        }

        @include sp {
          margin: 0;
          border-top: 2px solid $white;
        }
      }

      &.is-active {
        .l-headerNav_item {
          color: $main;
        }
      }
    }

    &_item {
      font-size: 14px;
      color: $black;

      @include pc {
        transition: color $anime;

        &:hover {
          color: $main;
        }
      }

      @include tab {
        font-size: 12px;
      }

      @include sp {
        width: 100%;
        padding: 1.2rem 1.5rem;
        box-sizing: border-box;
        display: block;
        font-size: 1.3rem;
        color: $white;
        background-color: rgba($main, 0.5);
        position: relative;
      }

      &.pc {
        @include sp {
          display: none !important;
        }
      }

      &.sp {
        @include pc {
          display: none !important;
        }

        @include sp {
          &:after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 6px 0 6px;
            border-color: $white transparent transparent transparent;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
          }
        }
      }

      &.is-active {
        @include sp {
          &:after {
            border-width: 0 6px 8px 6px;
            border-color: transparent transparent $white transparent;
          }
        }
      }
    }
  }

  &Mega {
    padding: 40px 32px 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background-color: $white;
    position: absolute;
    top: 120%;
    left: 0;
    z-index: 2;
    opacity: 0;
    pointer-events: none;

    @include pc {
      box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.26);
      transition: opacity $anime;

      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 9px 0 9px;
        border-color: $main transparent transparent transparent;
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 2;
        opacity: 0;
        transition: opacity $anime;

        @include tab {
          border-width: 12px 8px 0;
        }
      }
    }

    @include tab {
      padding: 28px 24px 16px;
    }

    @include sp {
      width: 100%;
      height: 0;
      padding: 0 2rem;
      display: block;
      position: static;
      opacity: 0;
      pointer-events: auto;
      transition: height 0.3s, padding 0.3s, opacity 0.2s ease;
      pointer-events: none;
    }

    &--human {
      width: 420px;
    }

    &--technical {
      width: 320px;
    }

    &.is-view {
      opacity: 1;
      pointer-events: auto;

      @include pc {
        &:after {
          opacity: 1;
        }
      }

      @include sp {
        height: auto;
        padding: 1.5rem 2rem;
        // display: block;
      }
    }

    &_box {
      & + & {
        margin: 0 0 0 24px;

        @include sp {
          margin: 2rem 0 0;
        }
      }
    }

    &_list {
      @include sp {
        & + & {
          margin: 24px 0 0;
        }
      }
    }

    &_link {
      padding: 12px 0;
      display: block;
      font-size: 14px;
      color: $black;

      @include pc {
        white-space: nowrap;
        transition: color $anime;

        &:hover {
          color: $main;
          // background-color: $blue;
        }
      }

      @include tab {
        font-size: 12px;
      }

      @include sp {
        padding: 0;
        display: flex;
        align-items: center;
        font-size: 1.1rem;

        &:before {
          content: 'ー';
          display: block;
          margin: 0 8px 0 0;
        }
      }
    }
  }
}

/* Safari用のハックは、Chromeに適用されないようにする */
@supports (-webkit-touch-callout: none) {
  .l-header_nav {
    /* Safari用のハック */
    height: -webkit-fill-available;
  }
}
