.c-heading {
  padding: 0 0 36px;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.12em;
  color: $main;

  @include sp {
    padding: 0 0 28px;
    font-size: 1.7rem;
  }

  &_sub {
    padding: 16px 0 0;
    display: block;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.1em;

    @include sp {
      padding: 8px 0 0;
      font-size: 1.3rem;
    }
  }

  &_center {
    padding: 0 0 28px;
    display: flex;
    justify-content: center;
    color: $main;

    @include sp {
      padding: 0 0 16px;
    }

    &Inner {
      display: inline-block;
      font-size: 35px;
      font-weight: 500;
      line-height: 2.4;
      border-bottom: 4px solid $main;

      @include sp {
        font-size: 1.6rem;
        line-height: 1.7;
        border-width: 2px;
      }
    }
  }

  &Box {
    &--btm {
      margin: 0 0 148px;

      @include sp {
        margin: 0 0 64px;

        @include sp {
          margin: 0 0 4rem;
        }
      }
    }

    &_lower {
      margin: 0 0 32px;
      padding: 16px 0;
      display: flex;
      align-items: flex-end;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.6;
      color: $main;
      border-bottom: 2px solid $main;

      @include sp {
        margin: 0 0 24px;
        padding: 8px 0;
        display: block;
        font-size: 1.6rem;
      }

      &Inner {
        padding: 0 0 0 36px;
        display: block;
        font-size: 20px;

        @include sp {
          padding: 0;
          font-size: 14px;
        }
      }

      &--inner {
        @extend .c-headingBox_lower;
        margin: 0 0 3%;
        padding: 0 0 1%;

        @include sp {
          margin: 0 0 1rem;
          padding: 0 0 1rem;
        }
      }
    }

    &_text {
      font-size: 14px;
      line-height: 2;

      @include sp {
        font-size: 1.2rem;
      }
    }
  }

  &Center {
    margin: 0 0 40px;

    @include sp {
      padding: 0 5%;
    }

    &_text {
      font-size: 16px;
      line-height: 2;
      text-align: center;

      @include sp {
        font-size: 1.2rem;
        text-align: left;
      }
    }
  }
}
