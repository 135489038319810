html {
  @include sp {
    font-size: calc(100vw / 32);
  }
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  overflow-x: hidden;

  @include sp {
    &.is-fixed {
      overflow: hidden;
    }
  }
}

img {
  width: 100%;
  height: auto;
  display: block;
}

main {
  margin: 150px 0 0;
  display: block;

  @include tab {
    margin: 98px 0 0;
  }

  @include sp {
    margin: 83px 0 0;
  }
}

.pc {
  @include pc {
    display: block;
  }

  @include sp {
    display: none !important;
  }
}

.sp {
  @include pc {
    display: none !important;
  }

  @include sp {
    display: block;
  }
}
