@charset "UTF-8";

// transition
$anime: 0.3s ease;

// color
// main
$main: #37ade0;

// white
$white: #fff;

// black
$black: #000;
$blackPale: #323232;

// gray
$gray: #919191;
$grayPale: #f0f0f0;
$grayPale02: #f9f9f9;
$grayDeep: #5b5b5b;

// aqua
$aquaPale: #e1f3fa;

// pink
$pink: #ffa0a0;
$pinkPale: #ffb3b3;

// orange
$orange: #ffaa30;

// yellow
$yellow: #ffd232;

// green
$green: #00be96;
$green01: #008c64;
$green02: #00a678;
$green03: #4cd1b5;

// blue
$blue: #6e8cff;
