.c-bnr {
  &_cont {
    display: flex;
    justify-content: space-between;

    @include sp {
      padding: 0 8%;
      display: block;
    }
  }

  &_list {
    width: 45%;

    @include pc {
      max-width: 450px;
    }

    @include sp {
      width: 100%;

      & + & {
        margin: 32px 0 0;
      }
    }
  }

  &_link {
    width: 100%;
    height: 100%;
    padding: 84px 0;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: $white;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include pc {
      &:before {
        transition: background-color $anime;
      }

      &:after {
        transition: transform 0.4s ease;
      }

      &:hover {
        &:before {
          background-color: rgba($black, 0);
        }

        &:after {
          transform: scale(1.2);
        }
      }
    }

    @include sp {
      padding: 56px 0;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba($black, 0.3);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform-origin: center;
      z-index: -2;
    }

    &--recruit {
      &:after {
        background: url('../img/common/site_recruit_pic.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &--group {
      &:after {
        background: url('../img/common/site_group_pic.jpg') no-repeat center;
        background-size: cover;
      }
    }
  }

  &_logo {
    width: 98px;
    margin: 0 0 12px;

    @include sp {
      width: 72px;
    }
  }

  &_lead {
    margin: 0 0 16px;
    padding: 0 0 16px;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0.1em;
    position: relative;

    @include sp {
      font-size: 1.6rem;
    }

    &:after {
      content: '';
      width: 48px;
      height: 2px;
      background-color: $white;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @include sp {
        width: 2.8rem;
      }
    }
  }

  &_text {
    font-size: 25px;
    font-weight: bold;

    @include sp {
      font-size: 1.3rem;
      letter-spacing: 0.02em;
    }
  }
}
